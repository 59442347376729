.flatpickr-calendar {
  pointer-events: auto;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}.dark .flatpickr-calendar {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 52, 56, 1);
  background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow:
    -2px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(253, 212, 2);
  border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
  height: 40px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
/* Arrow input */
.flatpickr-calendar.arrowTop:before {
  border-bottom-width: 1px;
  border-color: transparent;
}
/* Arrow input */
.flatpickr-calendar.arrowTop:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, 1);
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.dark .flatpickr-calendar.arrowTop:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(55, 52, 56, 1);
  border-bottom-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eee;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dark .flatpickr-months .flatpickr-month {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #000000;
  fill: #3c3f40;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: #373438;
}
.dark .flatpickr-months .flatpickr-prev-month,.dark 
.flatpickr-months .flatpickr-next-month {
  fill: #C8C8C8;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: blue;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
    /*rtl:begin:ignore*/
  /*
    */
  left: 0;
  /*
    /*rtl:end:ignore*/
  /*
    */
}
/*
    /*rtl:begin:ignore*/
/*
    /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
    /*rtl:begin:ignore*/
  /*
    */
  right: 0;
  /*
    /*rtl:end:ignore*/
  /*
    */
}
/*
    /*rtl:begin:ignore*/
/*
    /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #f64747;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #eab308;
  /* fill: #f64747; */
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(64, 72, 72, 0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(64, 72, 72, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(64, 72, 72, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(60, 63, 64, 0.5);
}
.dark .numInputWrapper:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 52, 56, 1);
  background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
/* Year arrow up*/
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(0, 0, 0, 1);
  border-bottom-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.dark .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, 1);
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
/* Year arrow down*/
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  --tw-border-opacity: 1;
  border-top-color: rgba(0, 0, 0, 1);
  border-top-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.dark .flatpickr-current-month .numInputWrapper span.arrowDown:after {
  --tw-border-opacity: 1;
  border-top-color: rgba(255, 255, 255, 1);
  border-top-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

/* MONTHS */
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  /* color: inherit; */
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
}
.dark span.flatpickr-weekday {
  --tw-text-opacity: 1;
  color: rgba(176, 175, 176, 1);
  color: rgba(176, 175, 176, var(--tw-text-opacity));
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #eee;
}
/* day Cell */
.dark .flatpickr-day {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

/* Cell hover */
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 219, 219, 1);
  background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}
.dark .flatpickr-day.inRange,.dark 
.flatpickr-day.prevMonthDay.inRange,.dark 
.flatpickr-day.nextMonthDay.inRange,.dark 
.flatpickr-day.today.inRange,.dark 
.flatpickr-day.prevMonthDay.today.inRange,.dark 
.flatpickr-day.nextMonthDay.today.inRange,.dark 
.flatpickr-day:hover,.dark 
.flatpickr-day.prevMonthDay:hover,.dark 
.flatpickr-day.nextMonthDay:hover,.dark 
.flatpickr-day:focus,.dark 
.flatpickr-day.prevMonthDay:focus,.dark 
.flatpickr-day.nextMonthDay:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 77, 80, 1);
  border-color: rgba(79, 77, 80, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(79, 77, 80, 1);
  background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
}

/* Selected today */
.flatpickr-day.today {
  /* box-shadow: inset 0px -3px 0px 0px theme("colors.brand"); */
}
/* Today hover */
.dark .flatpickr-day.today:hover,.dark 
.flatpickr-day.today:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #f64747;
  background: #f64747;
  color: #fff;
}

/* Selected day */
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, 1);
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(200, 200, 200, 1);
  color: rgba(200, 200, 200, var(--tw-text-opacity));
}
.dark .flatpickr-day.selected,.dark 
.flatpickr-day.startRange,.dark 
.flatpickr-day.endRange,.dark 
.flatpickr-day.selected.inRange,.dark 
.flatpickr-day.startRange.inRange,.dark 
.flatpickr-day.endRange.inRange,.dark 
.flatpickr-day.selected:focus,.dark 
.flatpickr-day.startRange:focus,.dark 
.flatpickr-day.endRange:focus,.dark 
.flatpickr-day.selected:hover,.dark 
.flatpickr-day.startRange:hover,.dark 
.flatpickr-day.endRange:hover,.dark 
.flatpickr-day.selected.prevMonthDay,.dark 
.flatpickr-day.startRange.prevMonthDay,.dark 
.flatpickr-day.endRange.prevMonthDay,.dark 
.flatpickr-day.selected.nextMonthDay,.dark 
.flatpickr-day.startRange.nextMonthDay,.dark 
.flatpickr-day.endRange.nextMonthDay {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, 1);
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(55, 52, 56, 1);
  color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  box-shadow: none;
  color: #fff;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
/* Range */
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
}
/* Days of the next moth */
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  --tw-text-opacity: 1;
  color: rgba(176, 175, 176, 1);
  color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .flatpickr-day.flatpickr-disabled,.dark 
.flatpickr-day.flatpickr-disabled:hover,.dark 
.flatpickr-day.prevMonthDay,.dark 
.flatpickr-day.nextMonthDay,.dark 
.flatpickr-day.notAllowed,.dark 
.flatpickr-day.notAllowed.prevMonthDay,.dark 
.flatpickr-day.notAllowed.nextMonthDay {
  --tw-text-opacity: 1;
  color: rgba(104, 102, 104, 1);
  color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  background: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(55, 52, 56, 0.1);
  color: rgba(55, 52, 56, var(--tw-text-opacity));
  --tw-text-opacity: 0.1;
}
.dark .flatpickr-day.flatpickr-disabled,.dark 
.flatpickr-day.flatpickr-disabled:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 77, 80, 1);
  color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
}
.flatpickr-day.week.selected {
  border-radius: 0;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #eee;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(64, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

/* Minutes and seconds arrow up */
.flatpickr-time .numInputWrapper span.arrowUp:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(55, 52, 56, 1);
  border-bottom-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.dark .flatpickr-time .numInputWrapper span.arrowUp:after {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(200, 200, 200, 1);
  border-bottom-color: rgba(200, 200, 200, var(--tw-border-opacity));
}
/* Minutes and seconds arrow down */
.flatpickr-time .numInputWrapper span.arrowDown:after {
  --tw-border-opacity: 1;
  border-top-color: rgba(55, 52, 56, 1);
  border-top-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.dark .flatpickr-time .numInputWrapper span.arrowDown:after {
  --tw-border-opacity: 1;
  border-top-color: rgba(176, 175, 176, 1);
  border-top-color: rgba(176, 175, 176, var(--tw-border-opacity));
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
/* hours and minutes text*/
.flatpickr-time input {
  --tw-text-opacity: 1;
  color: rgba(79, 77, 80, 1);
  color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .flatpickr-time input {
  --tw-text-opacity: 1;
  color: rgba(176, 175, 176, 1);
  color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
/* ':' and AM,  PM TEXT */
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  --tw-text-opacity: 1;
  color: rgba(79, 77, 80, 1);
  color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .flatpickr-time .flatpickr-time-separator,.dark 
.flatpickr-time .flatpickr-am-pm {
  --tw-text-opacity: 1;
  color: rgba(176, 175, 176, 1);
  color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

/* Hover cell hours */
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 207, 207, 1);
  background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.dark .flatpickr-time input:hover,.dark 
.flatpickr-time .flatpickr-am-pm:hover,.dark 
.flatpickr-time input:focus,.dark 
.flatpickr-time .flatpickr-am-pm:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 52, 56, 1);
  background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  width: 307.875px;
}
.dayContainer {
  padding: 0;
  border-right: 0;
}

/* cell days of month selected border */
span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border-radius: 0 !important;
  max-width: none;
  border-right-color: transparent;
}
span.flatpickr-day:nth-child(n + 8),
span.flatpickr-day.prevMonthDay:nth-child(n + 8),
span.flatpickr-day.nextMonthDay:nth-child(n + 8) {
  border-top-color: transparent;
}
span.flatpickr-day:nth-child(7n-6),
span.flatpickr-day.prevMonthDay:nth-child(7n-6),
span.flatpickr-day.nextMonthDay:nth-child(7n-6) {
  border-left: 0;
}
span.flatpickr-day:nth-child(n + 36),
span.flatpickr-day.prevMonthDay:nth-child(n + 36),
span.flatpickr-day.nextMonthDay:nth-child(n + 36) {
  border-bottom: 0;
}
span.flatpickr-day:nth-child(-n + 7),
span.flatpickr-day.prevMonthDay:nth-child(-n + 7),
span.flatpickr-day.nextMonthDay:nth-child(-n + 7) {
  margin-top: 0;
}

/* today borders */
span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  box-shadow: inset 0px -3px 0px 0px rgb(253, 212, 2);
  box-shadow: inset 0px -3px 0px 0px rgba(var(--brand, 253 212 2) / 1);
}
/* today borders hover */
span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #f64747;
}
.dark span.flatpickr-day.startRange,.dark 
span.flatpickr-day.prevMonthDay.startRange,.dark 
span.flatpickr-day.nextMonthDay.startRange,.dark 
span.flatpickr-day.endRange,.dark 
span.flatpickr-day.prevMonthDay.endRange,.dark 
span.flatpickr-day.nextMonthDay.endRange {
  --tw-border-opacity: 1;
  border-color: rgba(200, 200, 200, 1);
  border-color: rgba(200, 200, 200, var(--tw-border-opacity));
}
span.flatpickr-day.today,
span.flatpickr-day.prevMonthDay.today,
span.flatpickr-day.nextMonthDay.today,
span.flatpickr-day.selected,
span.flatpickr-day.prevMonthDay.selected,
span.flatpickr-day.nextMonthDay.selected {
  z-index: 2;
}
.rangeMode .flatpickr-day {
  margin-top: -1px;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: none;
}
.flatpickr-weekwrapper span.flatpickr-day {
  border: 0;
  margin: -1px 0 0 -1px;
}
.hasWeeks .flatpickr-days {
  border-right: 0;
}

@media screen and (min-width: 0\0) and (-webkit-min-device-pixel-ratio: 0.75), screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  span.flatpickr-day {
    display: block;
    flex: 1 0 auto;
  }
}

.map {
  height: 90vh;
  border-radius: 6px;
}

.map input {
  border: 1px solid #bdbdbd;
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  /* width: 264px; */
}

.map button {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-right: none;
  border-radius: 0px;
}

.map button.add-direction {
  border-right: 1px solid #bdbdbd;
}

.map button:first-of-type {
  border-radius: 0px;
  border-right: none;
  border-left: none;
}

.map button:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #bdbdbd;
}

.map button.geolocate-button {
  border-right: 1px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;
  border-radius: 4px !important;
}

/* https://github.com/tailwindlabs/tailwindcss/discussions/5969
 * Modified from https://unpkg.com/tailwindcss@3.1.8/src/css/preflight.css 
 */

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #CFCFCF; /* 2 */
}

::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
     tab-size: 4; /* 3 */
  font-family: var(--font-open-sans), Open Sans, sans-serif; /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
3. Modified selector to diminish specificity.
*/

button,
/* 3 */
input:where([type='button'], [type='reset'], [type='submit']) {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #B0AFB0; /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #B0AFB0; /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role='button'] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* TODO: Delete this h styles */
h1 {
    font-family: var(--font-open-sans), Open Sans, sans-serif;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700
}

h2 {
    font-family: var(--font-open-sans), Open Sans, sans-serif;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700
}

h3 {
    font-family: var(--font-open-sans), Open Sans, sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700
}

h4 {
    font-family: var(--font-open-sans), Open Sans, sans-serif;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700
}


  html {
    --csstools-color-scheme--light: initial;
    color-scheme: only light;
    background-color: #F6F6F6;
  }

  html.dark {
    background-color: #1F1C20;
  }

  .dark {
    --csstools-color-scheme--light:  ;
    color-scheme: only dark;
  }

  * {
    scrollbar-color: rgb(253, 212, 2) transparent;
    scrollbar-color: rgba(var(--brand, 253 212 2) / 1) transparent;
    scrollbar-width: thin;
  }
  .dark * {
    scrollbar-color: #686668 transparent;
    scrollbar-width: thin;
  }
  /* https://css-tricks.com/custom-scrollbars-in-webkit/ */
  *::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
  .dark *::-webkit-scrollbar-thumb{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  }
  *::-webkit-scrollbar-corner {
    background: transparent;
  }
  svg[display='none'] {
    display: none;
  }

*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
    --tw-ring-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
    --tw-ring-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

.\!container{
    width: 100% !important;
}

.container{
    width: 100%;
}

@media (min-width: 640px){

    .\!container{
        max-width: 640px !important;
    }

    .container{
        max-width: 640px;
    }
}

@media (min-width: 768px){

    .\!container{
        max-width: 768px !important;
    }

    .container{
        max-width: 768px;
    }
}

@media (min-width: 1024px){

    .\!container{
        max-width: 1024px !important;
    }

    .container{
        max-width: 1024px;
    }
}

@media (min-width: 1280px){

    .\!container{
        max-width: 1280px !important;
    }

    .container{
        max-width: 1280px;
    }
}

@media (min-width: 1536px){

    .\!container{
        max-width: 1536px !important;
    }

    .container{
        max-width: 1536px;
    }
}

.prose{
    color: var(--tw-prose-body);
    max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-lead);
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}

.prose :where(a):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-links);
    -webkit-text-decoration: underline;
    text-decoration: underline;
    font-weight: 500;
}

.prose :where(strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-bold);
    font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: decimal;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    list-style-type: disc;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}

.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
    font-weight: 400;
    color: var(--tw-prose-counters);
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
    color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 600;
    margin-top: 1.25em;
}

.prose :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    border-color: var(--tw-prose-hr);
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 500;
    font-style: italic;
    color: var(--tw-prose-quotes);
    border-left-width: 0.25rem;
    border-left-color: var(--tw-prose-quote-borders);
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
    content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
    content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 900;
    color: inherit;
}

.prose :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 800;
    color: inherit;
}

.prose :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 700;
    color: inherit;
}

.prose :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 700;
    color: inherit;
}

.prose :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-weight: 500;
    font-family: inherit;
    color: var(--tw-prose-kbd);
    box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
    font-size: 0.875em;
    border-radius: 0.3125rem;
    padding-top: 0.1875em;
    padding-right: 0.375em;
    padding-bottom: 0.1875em;
    padding-left: 0.375em;
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-code);
    font-weight: 600;
    font-size: 0.875em;
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
    content: "`";
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
    content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
    font-size: 0.875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
    font-size: 0.9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-pre-code);
    background-color: var(--tw-prose-pre-bg);
    overflow-x: auto;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding-top: 0.8571429em;
    padding-right: 1.1428571em;
    padding-bottom: 0.8571429em;
    padding-left: 1.1428571em;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
    content: none;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
    content: none;
}

.prose :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    width: 100%;
    table-layout: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
}

.prose :where(thead):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-headings);
    font-weight: 600;
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}

.prose :where(tbody tr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    border-top-width: 1px;
    border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    vertical-align: top;
}

.prose :where(th, td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    text-align: left;
}

.prose :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
    margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    color: var(--tw-prose-captions);
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
}

.prose{
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-kbd: #111827;
    --tw-prose-kbd-shadows: 17 24 39;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-kbd: #fff;
    --tw-prose-invert-kbd-shadows: 255 255 255;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: rgba(0, 0, 0, 0.5);
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
    margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0.375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0.375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.prose :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.5em;
    padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-top: 0.5714286em;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 0;
}

.prose-base{
    font-size: 1rem;
    line-height: 1.75;
}

.prose-base :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.prose-base :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}

.prose-base :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
}

.prose-base :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
}

.prose-base :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
}

.prose-base :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
}

.prose-base :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
}

.prose-base :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose-base :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose-base :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
    margin-bottom: 0;
}

.prose-base :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose-base :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
    border-radius: 0.3125rem;
    padding-top: 0.1875em;
    padding-right: 0.375em;
    padding-bottom: 0.1875em;
    padding-left: 0.375em;
}

.prose-base :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
}

.prose-base :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
}

.prose-base :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.9em;
}

.prose-base :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding-top: 0.8571429em;
    padding-right: 1.1428571em;
    padding-bottom: 0.8571429em;
    padding-left: 1.1428571em;
}

.prose-base :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}

.prose-base :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}

.prose-base :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.prose-base :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0.375em;
}

.prose-base :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0.375em;
}

.prose-base :where(.prose-base > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.prose-base :where(.prose-base > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
}

.prose-base :where(.prose-base > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 1.25em;
}

.prose-base :where(.prose-base > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
}

.prose-base :where(.prose-base > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 1.25em;
}

.prose-base :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.prose-base :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.prose-base :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 1.25em;
}

.prose-base :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0.5em;
    padding-left: 1.625em;
}

.prose-base :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 3em;
    margin-bottom: 3em;
}

.prose-base :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose-base :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose-base :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose-base :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose-base :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
    line-height: 1.7142857;
}

.prose-base :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}

.prose-base :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0;
}

.prose-base :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-right: 0;
}

.prose-base :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-top: 0.5714286em;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}

.prose-base :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-left: 0;
}

.prose-base :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    padding-right: 0;
}

.prose-base :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 2em;
    margin-bottom: 2em;
}

.prose-base :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
    margin-bottom: 0;
}

.prose-base :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
}

.prose-base :where(.prose-base > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-top: 0;
}

.prose-base :where(.prose-base > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
    margin-bottom: 0;
}

/* @import 'quick-react-all-components/index.css'; */

.dark .rs-container.nomodal .rs__control{
    --tw-border-opacity: 1;
    border-color: rgba(82, 82, 91, 1);
    border-color: rgba(82, 82, 91, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}

.rs-container .rs__control{
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(212, 212, 212, 1);
    border-color: rgba(212, 212, 212, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.rs-container .rs__control:hover{
    --tw-border-opacity: 1;
    border-color: rgba(163, 163, 163, 1);
    border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.dark .rs-container .rs__control{
    --tw-border-opacity: 1;
    border-color: rgba(113, 113, 122, 1);
    border-color: rgba(113, 113, 122, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}

.dark .rs-container .rs__control:hover{
    --tw-border-opacity: 1;
    border-color: rgba(115, 115, 115, 1);
    border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}

.rs-container .rs__control--is-focused{
    --tw-border-opacity: 1;
    border-color: rgba(115, 115, 115, 1);
    border-color: rgba(115, 115, 115, var(--tw-border-opacity));
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.rs-container .rs__control--is-focused:hover{
    --tw-border-opacity: 1;
    border-color: rgba(115, 115, 115, 1);
    border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}

.dark .rs-container .rs__control--is-focused{
    --tw-border-opacity: 1;
    border-color: rgba(163, 163, 163, 1);
    border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.dark .rs-container .rs__control--is-focused:hover{
    --tw-border-opacity: 1;
    border-color: rgba(163, 163, 163, 1);
    border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.rs-container .rs__control--is-disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 219, 219, 1);
    background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}

.dark .rs-container .rs__control--is-disabled{
    --tw-border-opacity: 1;
    border-color: rgba(79, 77, 80, 1);
    border-color: rgba(79, 77, 80, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}

.rs-container .rs__menu{
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(212, 212, 212, 1);
    border-color: rgba(212, 212, 212, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, 1);
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.dark .rs-container .rs__menu{
    --tw-border-opacity: 1;
    border-color: rgba(82, 82, 82, 1);
    border-color: rgba(82, 82, 82, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(64, 64, 64, 1);
    background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.rs-container .rs__option{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, 1);
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(82, 82, 82, 1);
    color: rgba(82, 82, 82, var(--tw-text-opacity));
}

.rs-container .rs__option:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, 1);
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.dark .rs-container .rs__option{
    --tw-bg-opacity: 1;
    background-color: rgba(64, 64, 64, 1);
    background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, 1);
    color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.dark .rs-container .rs__option:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(38, 38, 38, 1);
    background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}

.rs-container .rs__option--is-focused{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, 1);
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.dark .rs-container .rs__option--is-focused{
    --tw-bg-opacity: 1;
    background-color: rgba(38, 38, 38, 1);
    background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}

.rs-container .rs__indicator-separator{
    --tw-bg-opacity: 1;
    background-color: rgba(163, 163, 163, 1);
    background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}

.rs-container .rs__input-container,
  .rs-container .rs__placeholder,
  .rs-container .rs__single-value{
    --tw-text-opacity: 1;
    color: rgba(82, 82, 82, 1);
    color: rgba(82, 82, 82, var(--tw-text-opacity));
}

.dark .rs-container .rs__input-container,.dark 
  .rs-container .rs__placeholder,.dark 
  .rs-container .rs__single-value{
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, 1);
    color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.rs-container .rs__placeholder{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}

.dark .rs-container .rs__placeholder{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}

/* For use with React Select in base bg (Not modals)
  Add rs-base as prefix, and rs-base-container as className to the Select component
   */

/* Select point map searchbox styles */

.sPrS-container{
    border-radius: 0px;
}

.sPrS-container .sPrS__control{
    border-radius: 0px;
    border-width: 0;
}

.sPrS-container:first-child .sPrS__control{
    border-top-left-radius: 0.25rem;
}

@media (min-width: 640px){

    .sPrS-container:first-child .sPrS__control{
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
}

.sPrS-container:nth-child(2) .sPrS__control{
    border-top-right-radius: 0.25rem;
}

@media (min-width: 640px){

    .sPrS-container:nth-child(2) .sPrS__control{
        border-radius: 0px;
    }
}

.sPrS-container .sPrS__control--is-focused{
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.pointer-events-none{
    pointer-events: none;
}

.pointer-events-auto{
    pointer-events: auto;
}

.visible{
    visibility: visible;
}

.invisible{
    visibility: hidden;
}

.fixed{
    position: fixed;
}

.absolute{
    position: absolute;
}

.relative{
    position: relative;
}

.sticky{
    position: sticky;
}

.inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.-bottom-1{
    bottom: -0.25rem;
}

.-bottom-1\/2{
    bottom: -50%;
}

.-bottom-2{
    bottom: -0.5rem;
}

.-bottom-4{
    bottom: -1rem;
}

.-left-0{
    left: -0px;
}

.-left-0\.5{
    left: -0.125rem;
}

.-left-16{
    left: -4rem;
}

.-right-1{
    right: -0.25rem;
}

.-right-1\/2{
    right: -50%;
}

.-right-16{
    right: -4rem;
}

.-right-2{
    right: -0.5rem;
}

.-right-3{
    right: -0.75rem;
}

.-right-4{
    right: -1rem;
}

.-top-1\/2{
    top: -50%;
}

.-top-2{
    top: -0.5rem;
}

.-top-2\.5{
    top: -0.625rem;
}

.-top-3{
    top: -0.75rem;
}

.-top-4{
    top: -1rem;
}

.-top-5{
    top: -1.25rem;
}

.bottom-0{
    bottom: 0px;
}

.bottom-1{
    bottom: 0.25rem;
}

.bottom-1\/2{
    bottom: 50%;
}

.bottom-2{
    bottom: 0.5rem;
}

.bottom-3\/4{
    bottom: 75%;
}

.bottom-4{
    bottom: 1rem;
}

.bottom-8{
    bottom: 2rem;
}

.bottom-\[90\%\]{
    bottom: 90%;
}

.bottom-\[calc\(100\%-0\.5rem\)\]{
    bottom: calc(100% - 0.5rem);
}

.left-0{
    left: 0px;
}

.left-0\.5{
    left: 0.125rem;
}

.left-1{
    left: 0.25rem;
}

.left-1\.5{
    left: 0.375rem;
}

.left-1\/2{
    left: 50%;
}

.left-10{
    left: 2.5rem;
}

.left-11{
    left: 2.75rem;
}

.left-2{
    left: 0.5rem;
}

.left-2\.5{
    left: 0.625rem;
}

.left-20{
    left: 5rem;
}

.left-4{
    left: 1rem;
}

.left-5{
    left: 1.25rem;
}

.left-6{
    left: 1.5rem;
}

.left-7{
    left: 1.75rem;
}

.left-\[34px\]{
    left: 34px;
}

.left-\[58px\]{
    left: 58px;
}

.right-0{
    right: 0px;
}

.right-0\.5{
    right: 0.125rem;
}

.right-1{
    right: 0.25rem;
}

.right-1\/2{
    right: 50%;
}

.right-2{
    right: 0.5rem;
}

.right-4{
    right: 1rem;
}

.right-8{
    right: 2rem;
}

.right-\[5px\]{
    right: 5px;
}

.top-0{
    top: 0px;
}

.top-1{
    top: 0.25rem;
}

.top-1\/2{
    top: 50%;
}

.top-14{
    top: 3.5rem;
}

.top-16{
    top: 4rem;
}

.top-2{
    top: 0.5rem;
}

.top-28{
    top: 7rem;
}

.top-4{
    top: 1rem;
}

.top-8{
    top: 2rem;
}

.top-\[1px\]{
    top: 1px;
}

.top-\[5px\]{
    top: 5px;
}

.top-\[60\%\]{
    top: 60%;
}

.top-\[calc\(100\%-1rem\)\]{
    top: calc(100% - 1rem);
}

.top-full{
    top: 100%;
}

.isolate{
    isolation: isolate;
}

.\!z-30{
    z-index: 30 !important;
}

.\!z-40{
    z-index: 40 !important;
}

.\!z-50{
    z-index: 50 !important;
}

.\!z-75{
    z-index: 75 !important;
}

.-z-10{
    z-index: -10;
}

.z-0{
    z-index: 0;
}

.z-10{
    z-index: 10;
}

.z-100{
    z-index: 100;
}

.z-20{
    z-index: 20;
}

.z-25{
    z-index: 25;
}

.z-30{
    z-index: 30;
}

.z-40{
    z-index: 40;
}

.z-50{
    z-index: 50;
}

.z-75{
    z-index: 75;
}

.z-\[1000\]{
    z-index: 1000;
}

.z-\[100\]{
    z-index: 100;
}

.z-\[15\]{
    z-index: 15;
}

.z-\[1\]{
    z-index: 1;
}

.z-\[5\]{
    z-index: 5;
}

.z-\[800\]{
    z-index: 800;
}

.z-\[900\]{
    z-index: 900;
}

.z-\[9999\]{
    z-index: 9999;
}

.z-\[999\]{
    z-index: 999;
}

.order-first{
    order: -9999;
}

.order-last{
    order: 9999;
}

.\!col-span-2{
    grid-column: span 2 / span 2 !important;
}

.col-span-1{
    grid-column: span 1 / span 1;
}

.col-span-10{
    grid-column: span 10 / span 10;
}

.col-span-2{
    grid-column: span 2 / span 2;
}

.col-span-3{
    grid-column: span 3 / span 3;
}

.col-span-4{
    grid-column: span 4 / span 4;
}

.col-span-5{
    grid-column: span 5 / span 5;
}

.col-span-8{
    grid-column: span 8 / span 8;
}

.col-span-full{
    grid-column: 1 / -1;
}

.col-start-1{
    grid-column-start: 1;
}

.col-start-2{
    grid-column-start: 2;
}

.col-start-3{
    grid-column-start: 3;
}

.col-start-4{
    grid-column-start: 4;
}

.col-end-13{
    grid-column-end: 13;
}

.col-end-2{
    grid-column-end: 2;
}

.col-end-3{
    grid-column-end: 3;
}

.col-end-4{
    grid-column-end: 4;
}

.col-end-\[-1\]{
    grid-column-end: -1;
}

.col-end-\[-2\]{
    grid-column-end: -2;
}

.col-end-\[-5\]{
    grid-column-end: -5;
}

.row-span-2{
    grid-row: span 2 / span 2;
}

.row-span-3{
    grid-row: span 3 / span 3;
}

.row-span-full{
    grid-row: 1 / -1;
}

.row-start-1{
    grid-row-start: 1;
}

.row-start-2{
    grid-row-start: 2;
}

.row-start-3{
    grid-row-start: 3;
}

.row-start-5{
    grid-row-start: 5;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.m-1{
    margin: 0.25rem;
}

.m-10{
    margin: 2.5rem;
}

.m-2{
    margin: 0.5rem;
}

.m-4{
    margin: 1rem;
}

.m-6{
    margin: 1.5rem;
}

.m-8{
    margin: 2rem;
}

.m-\[7px\]{
    margin: 7px;
}

.m-auto{
    margin: auto;
}

.-my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
}

.-my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
}

.-my-4{
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.mx-0{
    margin-left: 0px;
    margin-right: 0px;
}

.mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem;
}

.mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
}

.mx-8{
    margin-left: 2rem;
    margin-right: 2rem;
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.my-0{
    margin-top: 0px;
    margin-bottom: 0px;
}

.my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

.my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.my-32{
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}

.-mb-1{
    margin-bottom: -0.25rem;
}

.-mb-2{
    margin-bottom: -0.5rem;
}

.-mb-3{
    margin-bottom: -0.75rem;
}

.-mb-4{
    margin-bottom: -1rem;
}

.-ml-10{
    margin-left: -2.5rem;
}

.-ml-16{
    margin-left: -4rem;
}

.-ml-8{
    margin-left: -2rem;
}

.-ml-9{
    margin-left: -2.25rem;
}

.-ml-\[44px\]{
    margin-left: -44px;
}

.-ml-\[76px\]{
    margin-left: -76px;
}

.-mr-10{
    margin-right: -2.5rem;
}

.-mr-6{
    margin-right: -1.5rem;
}

.-mt-1{
    margin-top: -0.25rem;
}

.-mt-2{
    margin-top: -0.5rem;
}

.mb-0{
    margin-bottom: 0px;
}

.mb-0\.5{
    margin-bottom: 0.125rem;
}

.mb-1{
    margin-bottom: 0.25rem;
}

.mb-2{
    margin-bottom: 0.5rem;
}

.mb-3{
    margin-bottom: 0.75rem;
}

.mb-4{
    margin-bottom: 1rem;
}

.mb-5{
    margin-bottom: 1.25rem;
}

.mb-6{
    margin-bottom: 1.5rem;
}

.mb-8{
    margin-bottom: 2rem;
}

.mb-\[10px\]{
    margin-bottom: 10px;
}

.ml-0{
    margin-left: 0px;
}

.ml-0\.5{
    margin-left: 0.125rem;
}

.ml-1{
    margin-left: 0.25rem;
}

.ml-2{
    margin-left: 0.5rem;
}

.ml-\[10px\]{
    margin-left: 10px;
}

.ml-auto{
    margin-left: auto;
}

.mr-1{
    margin-right: 0.25rem;
}

.mr-2{
    margin-right: 0.5rem;
}

.mr-4{
    margin-right: 1rem;
}

.mr-5{
    margin-right: 1.25rem;
}

.mr-auto{
    margin-right: auto;
}

.mt-0{
    margin-top: 0px;
}

.mt-1{
    margin-top: 0.25rem;
}

.mt-1\.5{
    margin-top: 0.375rem;
}

.mt-10{
    margin-top: 2.5rem;
}

.mt-2{
    margin-top: 0.5rem;
}

.mt-20{
    margin-top: 5rem;
}

.mt-3{
    margin-top: 0.75rem;
}

.mt-4{
    margin-top: 1rem;
}

.mt-5{
    margin-top: 1.25rem;
}

.mt-6{
    margin-top: 1.5rem;
}

.mt-8{
    margin-top: 2rem;
}

.mt-\[10px\]{
    margin-top: 10px;
}

.mt-px{
    margin-top: 1px;
}

.box-border{
    box-sizing: border-box;
}

.line-clamp-2{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.line-clamp-6{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

.block{
    display: block;
}

.inline-block{
    display: inline-block;
}

.inline{
    display: inline;
}

.flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.inline-flex{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.table{
    display: table;
}

.\!grid{
    display: grid !important;
}

.grid{
    display: grid;
}

.contents{
    display: contents;
}

.hidden{
    display: none;
}

.aspect-\[4\/3\]{
    aspect-ratio: 4/3;
}

.aspect-square{
    aspect-ratio: 1 / 1;
}

.size-1{
    width: 0.25rem;
    height: 0.25rem;
}

.size-12{
    width: 3rem;
    height: 3rem;
}

.size-16{
    width: 4rem;
    height: 4rem;
}

.size-2{
    width: 0.5rem;
    height: 0.5rem;
}

.size-20{
    width: 5rem;
    height: 5rem;
}

.size-24{
    width: 6rem;
    height: 6rem;
}

.size-3{
    width: 0.75rem;
    height: 0.75rem;
}

.size-3\.5{
    width: 0.875rem;
    height: 0.875rem;
}

.size-4{
    width: 1rem;
    height: 1rem;
}

.size-5{
    width: 1.25rem;
    height: 1.25rem;
}

.size-6{
    width: 1.5rem;
    height: 1.5rem;
}

.size-7{
    width: 1.75rem;
    height: 1.75rem;
}

.size-8{
    width: 2rem;
    height: 2rem;
}

.size-9{
    width: 2.25rem;
    height: 2.25rem;
}

.size-full{
    width: 100%;
    height: 100%;
}

.\!h-10{
    height: 2.5rem !important;
}

.\!h-max{
    height: -moz-max-content !important;
    height: -webkit-max-content !important;
    height: max-content !important;
}

.h-0{
    height: 0px;
}

.h-0\.5{
    height: 0.125rem;
}

.h-1{
    height: 0.25rem;
}

.h-1\.5{
    height: 0.375rem;
}

.h-1\/2{
    height: 50%;
}

.h-1\/3{
    height: 33.333333%;
}

.h-1\/4{
    height: 25%;
}

.h-10{
    height: 2.5rem;
}

.h-11{
    height: 2.75rem;
}

.h-12{
    height: 3rem;
}

.h-14{
    height: 3.5rem;
}

.h-16{
    height: 4rem;
}

.h-2{
    height: 0.5rem;
}

.h-2\.5{
    height: 0.625rem;
}

.h-20{
    height: 5rem;
}

.h-24{
    height: 6rem;
}

.h-28{
    height: 7rem;
}

.h-3{
    height: 0.75rem;
}

.h-3\.5{
    height: 0.875rem;
}

.h-3\/5{
    height: 60%;
}

.h-32{
    height: 8rem;
}

.h-36{
    height: 9rem;
}

.h-4{
    height: 1rem;
}

.h-40{
    height: 10rem;
}

.h-44{
    height: 11rem;
}

.h-48{
    height: 12rem;
}

.h-5{
    height: 1.25rem;
}

.h-5\/6{
    height: 83.333333%;
}

.h-52{
    height: 13rem;
}

.h-56{
    height: 14rem;
}

.h-6{
    height: 1.5rem;
}

.h-60{
    height: 15rem;
}

.h-64{
    height: 16rem;
}

.h-7{
    height: 1.75rem;
}

.h-8{
    height: 2rem;
}

.h-80{
    height: 20rem;
}

.h-84{
    height: 21rem;
}

.h-9{
    height: 2.25rem;
}

.h-96{
    height: 24rem;
}

.h-\[0\.6em\]{
    height: 0.6em;
}

.h-\[12rem\]{
    height: 12rem;
}

.h-\[143px\]{
    height: 143px;
}

.h-\[1em\]{
    height: 1em;
}

.h-\[1px\]{
    height: 1px;
}

.h-\[3\.25rem\]{
    height: 3.25rem;
}

.h-\[42px\]{
    height: 42px;
}

.h-\[45px\]{
    height: 45px;
}

.h-\[50vh\]{
    height: 50vh;
}

.h-\[65vh\]{
    height: 65vh;
}

.h-\[6rem\]{
    height: 6rem;
}

.h-\[80\%\]{
    height: 80%;
}

.h-\[calc\(100vh-12rem\)\]{
    height: calc(100vh - 12rem);
}

.h-\[calc\(100vh-20rem\)\]{
    height: calc(100vh - 20rem);
}

.h-\[calc\(100vh-2rem\)\]{
    height: calc(100vh - 2rem);
}

.h-\[max\(calc\(100\%-64px\)\2c 400px\)\]{
    height: max(calc(100% - 64px),400px);
}

.h-\[var\(--radix-navigation-menu-viewport-height\)\]{
    height: var(--radix-navigation-menu-viewport-height);
}

.h-auto{
    height: auto;
}

.h-fit{
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.h-full{
    height: 100%;
}

.h-max{
    height: -moz-max-content;
    height: -webkit-max-content;
    height: max-content;
}

.h-min{
    height: -moz-min-content;
    height: -webkit-min-content;
    height: min-content;
}

.h-px{
    height: 1px;
}

.h-screen{
    height: 100vh;
}

.max-h-10{
    max-height: 2.5rem;
}

.max-h-11{
    max-height: 2.75rem;
}

.max-h-12{
    max-height: 3rem;
}

.max-h-24{
    max-height: 6rem;
}

.max-h-28{
    max-height: 7rem;
}

.max-h-32{
    max-height: 8rem;
}

.max-h-36{
    max-height: 9rem;
}

.max-h-40{
    max-height: 10rem;
}

.max-h-48{
    max-height: 12rem;
}

.max-h-6{
    max-height: 1.5rem;
}

.max-h-60{
    max-height: 15rem;
}

.max-h-72{
    max-height: 18rem;
}

.max-h-80{
    max-height: 20rem;
}

.max-h-96{
    max-height: 24rem;
}

.max-h-\[12\.5rem\]{
    max-height: 12.5rem;
}

.max-h-\[143px\]{
    max-height: 143px;
}

.max-h-\[20vh\]{
    max-height: 20vh;
}

.max-h-\[25vw\]{
    max-height: 25vw;
}

.max-h-\[32rem\]{
    max-height: 32rem;
}

.max-h-\[45vw\]{
    max-height: 45vw;
}

.max-h-\[4rem\]{
    max-height: 4rem;
}

.max-h-\[50vh\]{
    max-height: 50vh;
}

.max-h-\[calc\(100vh-18rem\)\]{
    max-height: calc(100vh - 18rem);
}

.max-h-\[calc\(100vh-1rem\)\]{
    max-height: calc(100vh - 1rem);
}

.max-h-\[calc\(100vh-20rem\)\]{
    max-height: calc(100vh - 20rem);
}

.max-h-\[calc\(100vh-8\.5rem\)\]{
    max-height: calc(100vh - 8.5rem);
}

.max-h-\[max\(calc\(100vh-40rem\)\2c 14rem\)\]{
    max-height: max(calc(100vh - 40rem),14rem);
}

.max-h-full{
    max-height: 100%;
}

.max-h-screen{
    max-height: 100vh;
}

.\!min-h-\[80vh\]{
    min-height: 80vh !important;
}

.min-h-0{
    min-height: 0px;
}

.min-h-20{
    min-height: 5rem;
}

.min-h-60{
    min-height: 15rem;
}

.min-h-\[12rem\]{
    min-height: 12rem;
}

.min-h-\[2\.25rem\]{
    min-height: 2.25rem;
}

.min-h-\[20rem\]{
    min-height: 20rem;
}

.min-h-\[2px\]{
    min-height: 2px;
}

.min-h-\[4\.7ch\]{
    min-height: 4.7ch;
}

.min-h-\[41\.2px\]{
    min-height: 41.2px;
}

.min-h-\[49px\]{
    min-height: 49px;
}

.min-h-\[4rem\]{
    min-height: 4rem;
}

.min-h-\[6rem\]{
    min-height: 6rem;
}

.min-h-\[7rem\]{
    min-height: 7rem;
}

.min-h-\[80vh\]{
    min-height: 80vh;
}

.min-h-\[max\(20rem\2c 50vh\)\]{
    min-height: max(20rem,50vh);
}

.min-h-full{
    min-height: 100%;
}

.min-h-screen{
    min-height: 100vh;
}

.w-0{
    width: 0px;
}

.w-0\.5{
    width: 0.125rem;
}

.w-1{
    width: 0.25rem;
}

.w-1\.5{
    width: 0.375rem;
}

.w-1\/2{
    width: 50%;
}

.w-1\/3{
    width: 33.333333%;
}

.w-1\/4{
    width: 25%;
}

.w-10{
    width: 2.5rem;
}

.w-10\/12{
    width: 83.333333%;
}

.w-11{
    width: 2.75rem;
}

.w-11\/12{
    width: 91.666667%;
}

.w-12{
    width: 3rem;
}

.w-14{
    width: 3.5rem;
}

.w-16{
    width: 4rem;
}

.w-2{
    width: 0.5rem;
}

.w-2\.5{
    width: 0.625rem;
}

.w-2\/3{
    width: 66.666667%;
}

.w-20{
    width: 5rem;
}

.w-24{
    width: 6rem;
}

.w-28{
    width: 7rem;
}

.w-3{
    width: 0.75rem;
}

.w-3\.5{
    width: 0.875rem;
}

.w-3\/4{
    width: 75%;
}

.w-32{
    width: 8rem;
}

.w-36{
    width: 9rem;
}

.w-4{
    width: 1rem;
}

.w-4\/5{
    width: 80%;
}

.w-40{
    width: 10rem;
}

.w-48{
    width: 12rem;
}

.w-5{
    width: 1.25rem;
}

.w-5\/12{
    width: 41.666667%;
}

.w-5\/6{
    width: 83.333333%;
}

.w-52{
    width: 13rem;
}

.w-56{
    width: 14rem;
}

.w-6{
    width: 1.5rem;
}

.w-60{
    width: 15rem;
}

.w-64{
    width: 16rem;
}

.w-7{
    width: 1.75rem;
}

.w-7\/12{
    width: 58.333333%;
}

.w-72{
    width: 18rem;
}

.w-8{
    width: 2rem;
}

.w-8\/12{
    width: 66.666667%;
}

.w-80{
    width: 20rem;
}

.w-84{
    width: 21rem;
}

.w-9{
    width: 2.25rem;
}

.w-96{
    width: 24rem;
}

.w-\[0\.6em\]{
    width: 0.6em;
}

.w-\[10ch\]{
    width: 10ch;
}

.w-\[12rem\]{
    width: 12rem;
}

.w-\[1em\]{
    width: 1em;
}

.w-\[1px\]{
    width: 1px;
}

.w-\[300px\]{
    width: 300px;
}

.w-\[30ch\]{
    width: 30ch;
}

.w-\[30rem\]{
    width: 30rem;
}

.w-\[32rem\]{
    width: 32rem;
}

.w-\[35rem\]{
    width: 35rem;
}

.w-\[65ch\]{
    width: 65ch;
}

.w-\[6rem\]{
    width: 6rem;
}

.w-\[75vw\]{
    width: 75vw;
}

.w-\[86ch\]{
    width: 86ch;
}

.w-\[calc\(100\%-84px\)\]{
    width: calc(100% - 84px);
}

.w-\[min\(100vw\2c 32rem\)\]{
    width: min(100vw,32rem);
}

.w-\[min\(calc\(100\%-0\.5rem\)\2c 17rem\)\]{
    width: min(calc(100% - 0.5rem),17rem);
}

.w-\[min\(calc\(50\%-0\.5rem\)\2c 8rem\)\]{
    width: min(calc(50% - 0.5rem),8rem);
}

.w-auto{
    width: auto;
}

.w-fit{
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

.w-full{
    width: 100%;
}

.w-max{
    width: -moz-max-content;
    width: -webkit-max-content;
    width: max-content;
}

.w-min{
    width: -moz-min-content;
    width: -webkit-min-content;
    width: min-content;
}

.w-screen{
    width: 100vw;
}

.\!min-w-\[80vw\]{
    min-width: 80vw !important;
}

.min-w-0{
    min-width: 0px;
}

.min-w-10{
    min-width: 2.5rem;
}

.min-w-28{
    min-width: 7rem;
}

.min-w-32{
    min-width: 8rem;
}

.min-w-36{
    min-width: 9rem;
}

.min-w-72{
    min-width: 18rem;
}

.min-w-84{
    min-width: 21rem;
}

.min-w-96{
    min-width: 24rem;
}

.min-w-\[1\.25rem\]{
    min-width: 1.25rem;
}

.min-w-\[12rem\]{
    min-width: 12rem;
}

.min-w-\[1rem\]{
    min-width: 1rem;
}

.min-w-\[2\.75rem\]{
    min-width: 2.75rem;
}

.min-w-\[220px\]{
    min-width: 220px;
}

.min-w-\[7rem\]{
    min-width: 7rem;
}

.min-w-\[80vw\]{
    min-width: 80vw;
}

.min-w-\[min\(calc\(100vw-4rem\)\2c 32rem\)\]{
    min-width: min(calc(100vw - 4rem),32rem);
}

.min-w-\[min\(calc\(100vw-6rem\)\2c 20rem\)\]{
    min-width: min(calc(100vw - 6rem),20rem);
}

.min-w-fit{
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
}

.min-w-full{
    min-width: 100%;
}

.min-w-max{
    min-width: -moz-max-content;
    min-width: -webkit-max-content;
    min-width: max-content;
}

.\!max-w-\[calc\(100\%-3rem\)\]{
    max-width: calc(100% - 3rem) !important;
}

.\!max-w-prose{
    max-width: 65ch !important;
}

.max-w-2xl{
    max-width: 42rem;
}

.max-w-4xl{
    max-width: 56rem;
}

.max-w-\[20ch\]{
    max-width: 20ch;
}

.max-w-\[30ch\]{
    max-width: 30ch;
}

.max-w-\[50ch\]{
    max-width: 50ch;
}

.max-w-\[5rem\]{
    max-width: 5rem;
}

.max-w-\[60ch\]{
    max-width: 60ch;
}

.max-w-\[65ch\]{
    max-width: 65ch;
}

.max-w-\[92\%\]{
    max-width: 92%;
}

.max-w-\[calc\(100\%-1rem\)\]{
    max-width: calc(100% - 1rem);
}

.max-w-\[calc\(100vw-16rem\)\]{
    max-width: calc(100vw - 16rem);
}

.max-w-\[calc\(100vw-4rem\)\]{
    max-width: calc(100vw - 4rem);
}

.max-w-\[calc\(100vw-5rem\)\]{
    max-width: calc(100vw - 5rem);
}

.max-w-\[min\(calc\(100vw-4rem\)\2c 24rem\)\]{
    max-width: min(calc(100vw - 4rem),24rem);
}

.max-w-fit{
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
}

.max-w-full{
    max-width: 100%;
}

.max-w-lg{
    max-width: 32rem;
}

.max-w-max{
    max-width: -moz-max-content;
    max-width: -webkit-max-content;
    max-width: max-content;
}

.max-w-md{
    max-width: 28rem;
}

.max-w-none{
    max-width: none;
}

.max-w-prose{
    max-width: 65ch;
}

.max-w-sm{
    max-width: 24rem;
}

.max-w-xl{
    max-width: 36rem;
}

.max-w-xs{
    max-width: 20rem;
}

.flex-1{
    flex: 1 1 0%;
}

.flex-shrink-0{
    flex-shrink: 0;
}

.shrink{
    flex-shrink: 1;
}

.shrink-0{
    flex-shrink: 0;
}

.flex-grow{
    flex-grow: 1;
}

.grow{
    flex-grow: 1;
}

.basis-1\/2{
    flex-basis: 50%;
}

.basis-1\/5{
    flex-basis: 20%;
}

.basis-10{
    flex-basis: 2.5rem;
}

.basis-16{
    flex-basis: 4rem;
}

.basis-2\/3{
    flex-basis: 66.666667%;
}

.basis-20{
    flex-basis: 5rem;
}

.basis-3\/5{
    flex-basis: 60%;
}

.basis-48{
    flex-basis: 12rem;
}

.basis-7{
    flex-basis: 1.75rem;
}

.basis-9{
    flex-basis: 2.25rem;
}

.basis-\[18ch\]{
    flex-basis: 18ch;
}

.table-auto{
    table-layout: auto;
}

.border-separate{
    border-collapse: separate;
}

.border-spacing-y-1{
    --tw-border-spacing-y: 0.25rem;
    border-spacing: var(--tw-border-spacing-x) 0.25rem;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-center{
    transform-origin: center;
}

.origin-left{
    transform-origin: left;
}

.origin-right{
    transform-origin: right;
}

.-translate-x-1{
    --tw-translate-x: -0.25rem;
    transform: translate(-0.25rem, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2{
    --tw-translate-x: -50%;
    transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/7{
    --tw-translate-x: -14.2857143%;
    transform: translate(-14.2857143%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full{
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), -100%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2{
    --tw-translate-x: 50%;
    transform: translate(50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[0\.175em\]{
    --tw-translate-x: 0.175em;
    transform: translate(0.175em, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[0\.2em\]{
    --tw-translate-x: 0.2em;
    transform: translate(0.2em, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[1\.1em\]{
    --tw-translate-x: 1.1em;
    transform: translate(1.1em, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[1\.2em\]{
    --tw-translate-x: 1.2em;
    transform: translate(1.2em, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[1px\]{
    --tw-translate-x: 1px;
    transform: translate(1px, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0{
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), 0px) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0\.5{
    --tw-translate-y: 0.125rem;
    transform: translate(var(--tw-translate-x), 0.125rem) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2{
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), 50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2{
    --tw-translate-y: 0.5rem;
    transform: translate(var(--tw-translate-x), 0.5rem) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2\.5{
    --tw-translate-y: 0.625rem;
    transform: translate(var(--tw-translate-x), 0.625rem) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-36{
    --tw-translate-y: 9rem;
    transform: translate(var(--tw-translate-x), 9rem) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.15em\]{
    --tw-translate-y: 0.15em;
    transform: translate(var(--tw-translate-x), 0.15em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.175em\]{
    --tw-translate-y: 0.175em;
    transform: translate(var(--tw-translate-x), 0.175em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.2em\]{
    --tw-translate-y: 0.2em;
    transform: translate(var(--tw-translate-x), 0.2em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[1px\]{
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), 1px) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full{
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), 100%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90{
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0{
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(0deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45{
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-15deg\]{
    --tw-rotate: -15deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-15deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[15deg\]{
    --tw-rotate: 15deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(15deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1) scaleY(1);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1.25) scaleY(1.25);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(.95) scaleY(.95);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[65\%\]{
    --tw-scale-x: 65%;
    --tw-scale-y: 65%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(65%) scaleY(65%);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-0{
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(0) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes envTag{

    0%,100%{
        background-position: 0 0;
    }

    50%{
        background-position: 0 100%;
    }
}

.animate-envTag{
    animation: envTag 1s ease-in-out infinite;
}

@keyframes fade-in-down{

    0%{
        opacity: 0;
        transform: translateY(-2px);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in-down{
    animation: fade-in-down 0.5s ease-out;
}

@keyframes fadeIn{

    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

.animate-fadeIn{
    animation: fadeIn 300ms cubic-bezier(0.16, 0, 0.13, 1);
}

@keyframes fadeOut{

    0%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.animate-fadeOut{
    animation: fadeOut 300ms cubic-bezier(0.16, 0, 0.13, 1);
}

@keyframes ping{

    75%, 100%{
        transform: scale(2);
        opacity: 0;
    }
}

.animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes pulse{

    50%{
        opacity: .5;
    }
}

.animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin{

    to{
        transform: rotate(360deg);
    }
}

.animate-spin{
    animation: spin 1s linear infinite;
}

.\!cursor-pointer{
    cursor: pointer !important;
}

.cursor-default{
    cursor: default;
}

.cursor-grab{
    cursor: -webkit-grab;
    cursor: grab;
}

.cursor-help{
    cursor: help;
}

.cursor-pointer{
    cursor: pointer;
}

.select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.select-text{
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
}

.select-all{
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all;
}

.resize-none{
    resize: none;
}

.\!resize{
    resize: both !important;
}

.resize{
    resize: both;
}

.snap-x{
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.scroll-m-4{
    scroll-margin: 1rem;
}

.scroll-mt-44{
    scroll-margin-top: 11rem;
}

.list-inside{
    list-style-position: inside;
}

.list-disc{
    list-style-type: disc;
}

.list-none{
    list-style-type: none;
}

.columns-1{
    -moz-columns: 1;
         columns: 1;
}

.break-before-auto{
    page-break-before: auto;
       -moz-column-break-before: auto;
            break-before: auto;
}

.grid-flow-col{
    grid-auto-flow: column;
}

.grid-flow-col-dense{
    grid-auto-flow: column dense;
}

.grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-\[1\.5rem\2c 1fr\]{
    grid-template-columns: 1.5rem 1fr;
}

.grid-cols-\[1fr\2c 2rem\]{
    grid-template-columns: 1fr 2rem;
}

.grid-cols-\[1fr\2c 5rem\]{
    grid-template-columns: 1fr 5rem;
}

.grid-cols-\[1fr\2c auto\]{
    grid-template-columns: 1fr auto;
}

.grid-cols-\[1fr-auto-auto\]{
    grid-template-columns: 1fr-auto-auto;
}

.grid-cols-\[1fr_2rem\]{
    grid-template-columns: 1fr 2rem;
}

.grid-cols-\[1fr_2rem_2rem_2rem\]{
    grid-template-columns: 1fr 2rem 2rem 2rem;
}

.grid-cols-\[1fr_auto\]{
    grid-template-columns: 1fr auto;
}

.grid-cols-\[2\.5rem\2c auto\]{
    grid-template-columns: 2.5rem auto;
}

.grid-cols-\[2fr_1fr_1fr\]{
    grid-template-columns: 2fr 1fr 1fr;
}

.grid-cols-\[2rem\2c auto\]{
    grid-template-columns: 2rem auto;
}

.grid-cols-\[2rem_auto_auto\]{
    grid-template-columns: 2rem auto auto;
}

.grid-cols-\[3rem\2c 1fr\2c auto\]{
    grid-template-columns: 3rem 1fr auto;
}

.grid-cols-\[3rem_auto_auto\]{
    grid-template-columns: 3rem auto auto;
}

.grid-cols-\[3rem_minmax\(0\2c 1fr\)\]{
    grid-template-columns: 3rem minmax(0,1fr);
}

.grid-cols-\[3rem_minmax\(0\2c auto\)\]{
    grid-template-columns: 3rem minmax(0,auto);
}

.grid-cols-\[90\%_auto\]{
    grid-template-columns: 90% auto;
}

.grid-cols-\[auto\2c 1fr\]{
    grid-template-columns: auto 1fr;
}

.grid-cols-\[auto_1fr\]{
    grid-template-columns: auto 1fr;
}

.grid-cols-\[auto_1fr_auto\]{
    grid-template-columns: auto 1fr auto;
}

.grid-cols-\[auto_1fr_auto_auto\]{
    grid-template-columns: auto 1fr auto auto;
}

.grid-cols-\[auto_auto\]{
    grid-template-columns: auto auto;
}

.grid-cols-\[auto_auto_1fr\]{
    grid-template-columns: auto auto 1fr;
}

.grid-cols-\[repeat\(5\2c auto\)\]{
    grid-template-columns: repeat(5,auto);
}

.grid-cols-\[repeat\(auto-fill\2c minmax\(10rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fill,minmax(10rem,1fr));
}

.grid-cols-\[repeat\(auto-fill\2c minmax\(12rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fill,minmax(12rem,1fr));
}

.grid-cols-\[repeat\(auto-fill\2c minmax\(7rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fill,minmax(7rem,1fr));
}

.grid-cols-\[repeat\(auto-fill\2c minmax\(8rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fill,minmax(8rem,1fr));
}

.grid-cols-\[repeat\(auto-fit\2c minmax\(14rem\2c 20rem\)\)\]{
    grid-template-columns: repeat(auto-fit,minmax(14rem,20rem));
}

.grid-cols-\[repeat\(auto-fit\2c minmax\(8rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fit,minmax(8rem,1fr));
}

.grid-cols-\[repeat\(auto-fit\2c minmax\(9rem\2c 1fr\)\)\]{
    grid-template-columns: repeat(auto-fit,minmax(9rem,1fr));
}

.grid-cols-subgrid{
    grid-template-columns: subgrid;
}

.grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-\[1fr-auto\]{
    grid-template-rows: 1fr-auto;
}

.grid-rows-\[1fr_auto\]{
    grid-template-rows: 1fr auto;
}

.grid-rows-\[1fr_auto_1fr\]{
    grid-template-rows: 1fr auto 1fr;
}

.grid-rows-\[auto\2c 1fr\2c auto\]{
    grid-template-rows: auto 1fr auto;
}

.grid-rows-\[auto\2c 1fr\]{
    grid-template-rows: auto 1fr;
}

.grid-rows-\[auto_1fr\]{
    grid-template-rows: auto 1fr;
}

.grid-rows-\[auto_1fr_auto\]{
    grid-template-rows: auto 1fr auto;
}

.grid-rows-\[auto_auto\]{
    grid-template-rows: auto auto;
}

.grid-rows-\[auto_auto_1fr\]{
    grid-template-rows: auto auto 1fr;
}

.grid-rows-\[auto_auto_1fr_auto\]{
    grid-template-rows: auto auto 1fr auto;
}

.grid-rows-\[auto_auto_auto_1fr_auto\]{
    grid-template-rows: auto auto auto 1fr auto;
}

.grid-rows-\[repeat\(2\2c minmax\(0\2c auto\)\)\]{
    grid-template-rows: repeat(2,minmax(0,auto));
}

.grid-rows-\[repeat\(3\2c minmax\(0\2c auto\)\)\]{
    grid-template-rows: repeat(3,minmax(0,auto));
}

.flex-row{
    flex-direction: row;
}

.flex-row-reverse{
    flex-direction: row-reverse;
}

.flex-col{
    flex-direction: column;
}

.flex-col-reverse{
    flex-direction: column-reverse;
}

.flex-wrap{
    flex-wrap: wrap;
}

.flex-nowrap{
    flex-wrap: nowrap;
}

.place-content-end{
    align-content: end;
    justify-content: end;
    place-content: end;
}

.content-center{
    align-content: center;
}

.items-start{
    align-items: flex-start;
}

.items-end{
    align-items: flex-end;
}

.items-center{
    align-items: center;
}

.items-baseline{
    align-items: baseline;
}

.items-stretch{
    align-items: stretch;
}

.justify-start{
    justify-content: flex-start;
}

.justify-end{
    justify-content: flex-end;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}

.justify-around{
    justify-content: space-around;
}

.justify-evenly{
    justify-content: space-evenly;
}

.justify-stretch{
    justify-content: stretch;
}

.justify-items-start{
    justify-items: start;
}

.justify-items-center{
    justify-items: center;
}

.gap-0{
    gap: 0px;
}

.gap-0\.5{
    gap: 0.125rem;
}

.gap-1{
    gap: 0.25rem;
}

.gap-10{
    gap: 2.5rem;
}

.gap-12{
    gap: 3rem;
}

.gap-2{
    gap: 0.5rem;
}

.gap-2\.5{
    gap: 0.625rem;
}

.gap-24{
    gap: 6rem;
}

.gap-3{
    gap: 0.75rem;
}

.gap-4{
    gap: 1rem;
}

.gap-5{
    gap: 1.25rem;
}

.gap-6{
    gap: 1.5rem;
}

.gap-7{
    gap: 1.75rem;
}

.gap-8{
    gap: 2rem;
}

.gap-x-1{
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}

.gap-x-10{
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
}

.gap-x-16{
    -moz-column-gap: 4rem;
         column-gap: 4rem;
}

.gap-x-2{
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}

.gap-x-4{
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}

.gap-x-6{
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}

.gap-x-8{
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}

.gap-y-1{
    row-gap: 0.25rem;
}

.gap-y-2{
    row-gap: 0.5rem;
}

.gap-y-4{
    row-gap: 1rem;
}

.gap-y-6{
    row-gap: 1.5rem;
}

.gap-y-7{
    row-gap: 1.75rem;
}

.gap-y-8{
    row-gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * 0);
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - 0));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
    margin-left: calc(1rem * calc(1 - 0));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - 0));
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-top: calc(0.25rem * calc(1 - 0));
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * 0);
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - 0));
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-top: calc(0.5rem * calc(1 - 0));
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * 0);
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - 0));
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-top: calc(1rem * calc(1 - 0));
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * 0);
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - 0));
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-top-width: calc(1px * calc(1 - 0));
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * 0);
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - 0));
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-top-width: calc(2px * calc(1 - 0));
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * 0);
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-gray > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 139, 139, 1);
    border-color: rgba(139, 139, 139, var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(207, 207, 207, 1);
    border-color: rgba(207, 207, 207, var(--tw-divide-opacity));
}

.place-self-start{
    align-self: start;
    justify-self: start;
    place-self: start;
}

.place-self-end{
    align-self: end;
    justify-self: end;
    place-self: end;
}

.place-self-center{
    align-self: center;
    justify-self: center;
    place-self: center;
}

.self-start{
    align-self: flex-start;
}

.self-end{
    align-self: flex-end;
}

.self-center{
    align-self: center;
}

.self-stretch{
    align-self: stretch;
}

.justify-self-start{
    justify-self: start;
}

.justify-self-end{
    justify-self: end;
}

.justify-self-center{
    justify-self: center;
}

.justify-self-stretch{
    justify-self: stretch;
}

.overflow-auto{
    overflow: auto;
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-visible{
    overflow: visible;
}

.overflow-x-auto{
    overflow-x: auto;
}

.overflow-y-auto{
    overflow-y: auto;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.overflow-y-hidden{
    overflow-y: hidden;
}

.overflow-y-visible{
    overflow-y: visible;
}

.scroll-auto{
    scroll-behavior: auto;
}

.truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hyphens-auto{
    -webkit-hyphens: auto;
        hyphens: auto;
}

.whitespace-nowrap{
    white-space: nowrap;
}

.whitespace-pre{
    white-space: pre;
}

.whitespace-pre-line{
    white-space: pre-line;
}

.whitespace-pre-wrap{
    white-space: pre-wrap;
}

.text-balance{
    text-wrap: balance;
}

.text-pretty{
    text-wrap: pretty;
}

.break-words{
    word-wrap: break-word;
}

.break-all{
    word-break: break-all;
}

.break-keep{
    word-break: keep-all;
}

.rounded{
    border-radius: 0.25rem;
}

.rounded-2xl{
    border-radius: 1rem;
}

.rounded-3xl{
    border-radius: 1.5rem;
}

.rounded-\[10px\]{
    border-radius: 10px;
}

.rounded-\[32px\]{
    border-radius: 32px;
}

.rounded-full{
    border-radius: 9999px;
}

.rounded-lg{
    border-radius: 0.5rem;
}

.rounded-md{
    border-radius: 0.375rem;
}

.rounded-sm{
    border-radius: 0.125rem;
}

.rounded-xl{
    border-radius: 0.75rem;
}

.\!rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.rounded-l-\[3px\]{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
}

.rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.rounded-bl{
    border-bottom-left-radius: 0.25rem;
}

.rounded-bl-xl{
    border-bottom-left-radius: 0.75rem;
}

.rounded-br-xl{
    border-bottom-right-radius: 0.75rem;
}

.rounded-tl-sm{
    border-top-left-radius: 0.125rem;
}

.rounded-tl-xl{
    border-top-left-radius: 0.75rem;
}

.rounded-tr-xl{
    border-top-right-radius: 0.75rem;
}

.\!border-0{
    border-width: 0 !important;
}

.border{
    border-width: 1px;
}

.border-2{
    border-width: 2px;
}

.border-3{
    border-width: 3px;
}

.border-4{
    border-width: 4px;
}

.border-6{
    border-width: 6px;
}

.border-8{
    border-width: 8px;
}

.border-y{
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.border-y-2{
    border-top-width: 2px;
    border-bottom-width: 2px;
}

.border-b{
    border-bottom-width: 1px;
}

.border-b-2{
    border-bottom-width: 2px;
}

.border-l{
    border-left-width: 1px;
}

.border-l-0{
    border-left-width: 0;
}

.border-l-2{
    border-left-width: 2px;
}

.border-l-3{
    border-left-width: 3px;
}

.border-l-4{
    border-left-width: 4px;
}

.border-r{
    border-right-width: 1px;
}

.border-r-0{
    border-right-width: 0;
}

.border-r-2{
    border-right-width: 2px;
}

.border-t{
    border-top-width: 1px;
}

.border-t-0{
    border-top-width: 0;
}

.border-t-2{
    border-top-width: 2px;
}

.border-t-3{
    border-top-width: 3px;
}

.border-t-4{
    border-top-width: 4px;
}

.border-solid{
    border-style: solid;
}

.border-dashed{
    border-style: dashed;
}

.border-dotted{
    border-style: dotted;
}

.border-background-100{
    --tw-border-opacity: 1;
    border-color: rgba(104, 102, 104, 1);
    border-color: rgba(104, 102, 104, var(--tw-border-opacity));
}

.border-background-light{
    --tw-border-opacity: 1;
    border-color: rgba(246, 246, 246, 1);
    border-color: rgba(246, 246, 246, var(--tw-border-opacity));
}

.border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-brand{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}

.border-brand-dark{
    --tw-border-opacity: 1;
    border-color: rgb(221, 186, 2);
    border-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-border-opacity));
}

.border-contrast-light{
    --tw-border-opacity: 1;
    border-color: rgba(244, 244, 251, 1);
    border-color: rgba(244, 244, 251, var(--tw-border-opacity));
}

.border-current{
    border-color: currentColor;
}

.border-danger{
    --tw-border-opacity: 1;
    border-color: rgba(223, 41, 53, 1);
    border-color: rgba(223, 41, 53, var(--tw-border-opacity));
}

.border-gray{
    --tw-border-opacity: 1;
    border-color: rgba(139, 139, 139, 1);
    border-color: rgba(139, 139, 139, var(--tw-border-opacity));
}

.border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 219, 219, 1);
    border-color: rgba(219, 219, 219, var(--tw-border-opacity));
}

.border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(207, 207, 207, 1);
    border-color: rgba(207, 207, 207, var(--tw-border-opacity));
}

.border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(200, 200, 200, 1);
    border-color: rgba(200, 200, 200, var(--tw-border-opacity));
}

.border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(176, 175, 176, 1);
    border-color: rgba(176, 175, 176, var(--tw-border-opacity));
}

.border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(104, 102, 104, 1);
    border-color: rgba(104, 102, 104, var(--tw-border-opacity));
}

.border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 77, 80, 1);
    border-color: rgba(79, 77, 80, var(--tw-border-opacity));
}

.border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 28, 32, 1);
    border-color: rgba(31, 28, 32, var(--tw-border-opacity));
}

.border-gray-light-4{
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, 1);
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.border-gray-light-5{
    --tw-border-opacity: 1;
    border-color: rgba(242, 242, 242, 1);
    border-color: rgba(242, 242, 242, var(--tw-border-opacity));
}

.border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(34, 197, 94, 1);
    border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}

.border-primary{
    --tw-border-opacity: 1;
    border-color: rgba(13, 3, 252, 1);
    border-color: rgba(13, 3, 252, var(--tw-border-opacity));
}

.border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 232, 255, 1);
    border-color: rgba(243, 232, 255, var(--tw-border-opacity));
}

.border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, 1);
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, 1);
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, 1);
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, 1);
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.border-success{
    --tw-border-opacity: 1;
    border-color: rgba(0, 129, 72, 1);
    border-color: rgba(0, 129, 72, var(--tw-border-opacity));
}

.border-transparent{
    border-color: transparent;
}

.border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(250, 204, 21, 1);
    border-color: rgba(250, 204, 21, var(--tw-border-opacity));
}

.border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(234, 179, 8, 1);
    border-color: rgba(234, 179, 8, var(--tw-border-opacity));
}

.border-b-background-light{
    --tw-border-opacity: 1;
    border-bottom-color: rgba(246, 246, 246, 1);
    border-bottom-color: rgba(246, 246, 246, var(--tw-border-opacity));
}

.border-b-gray-100{
    --tw-border-opacity: 1;
    border-bottom-color: rgba(219, 219, 219, 1);
    border-bottom-color: rgba(219, 219, 219, var(--tw-border-opacity));
}

.border-b-gray-300{
    --tw-border-opacity: 1;
    border-bottom-color: rgba(200, 200, 200, 1);
    border-bottom-color: rgba(200, 200, 200, var(--tw-border-opacity));
}

.border-l-brand{
    --tw-border-opacity: 1;
    border-left-color: rgb(253, 212, 2);
    border-left-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}

.border-t-gray-300{
    --tw-border-opacity: 1;
    border-top-color: rgba(200, 200, 200, 1);
    border-top-color: rgba(200, 200, 200, var(--tw-border-opacity));
}

.border-t-transparent{
    border-top-color: transparent;
}

.\!bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(104, 102, 104, 1) !important;
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity)) !important;
}

.\!bg-transparent{
    background-color: transparent !important;
}

.bg-\[\#595959\]{
    --tw-bg-opacity: 1;
    background-color: rgba(89, 89, 89, 1);
    background-color: rgba(89, 89, 89, var(--tw-bg-opacity));
}

.bg-background-10{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}

.bg-background-group{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-background-light{
    --tw-bg-opacity: 1;
    background-color: rgba(246, 246, 246, 1);
    background-color: rgba(246, 246, 246, var(--tw-bg-opacity));
}

.bg-background-modal\/25{
    background-color: rgba(3, 1, 47, 0.25);
}

.bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-black\/40{
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, 1);
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, 1);
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, 1);
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.bg-brand{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}

.bg-brand-dark{
    --tw-bg-opacity: 1;
    background-color: rgb(221, 186, 2);
    background-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-bg-opacity));
}

.bg-contrast-light{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 251, 1);
    background-color: rgba(244, 244, 251, var(--tw-bg-opacity));
}

.bg-current{
    background-color: currentColor;
}

.bg-gray{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 139, 139, 1);
    background-color: rgba(139, 139, 139, var(--tw-bg-opacity));
}

.bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 219, 219, 1);
    background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}

.bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}

.bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}

.bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(176, 175, 176, 1);
    background-color: rgba(176, 175, 176, var(--tw-bg-opacity));
}

.bg-gray-400\/50{
    background-color: rgba(176, 175, 176, 0.5);
}

.bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, 1);
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}

.bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}

.bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}

.bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}

.bg-gray-light{
    --tw-bg-opacity: 1;
    background-color: rgba(216, 216, 216, 1);
    background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
}

.bg-gray-light-1{
    --tw-bg-opacity: 1;
    background-color: rgba(222, 222, 222, 1);
    background-color: rgba(222, 222, 222, var(--tw-bg-opacity));
}

.bg-gray-light-2{
    --tw-bg-opacity: 1;
    background-color: rgba(241, 241, 241, 1);
    background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
}

.bg-gray-light-3{
    --tw-bg-opacity: 1;
    background-color: rgba(225, 224, 255, 1);
    background-color: rgba(225, 224, 255, var(--tw-bg-opacity));
}

.bg-gray-light-5{
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}

.bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 252, 231, 1);
    background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}

.bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(187, 247, 208, 1);
    background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}

.bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(134, 239, 172, 1);
    background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}

.bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}

.bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(34, 197, 94, 1);
    background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}

.bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(22, 163, 74, 1);
    background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}

.bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(21, 128, 61, 1);
    background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}

.bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, 1);
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.bg-orange-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 215, 170, 1);
    background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.bg-orange-500{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 115, 22, 1);
    background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, 1);
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, 1);
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, 1);
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, 1);
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, 1);
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, 1);
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.bg-slate-100{
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, 1);
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-slate-200{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, 1);
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-stone-200{
    --tw-bg-opacity: 1;
    background-color: rgba(231, 229, 228, 1);
    background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}

.bg-transparent{
    background-color: transparent;
}

.bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-white\/80{
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 249, 195, 1);
    background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}

.bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 240, 138, 1);
    background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}

.bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 224, 71, 1);
    background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}

.bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(250, 204, 21, 1);
    background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}

.bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(202, 138, 4, 1);
    background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}

.bg-zinc-100{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, 1);
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}

.bg-opacity-25{
    --tw-bg-opacity: 0.25;
}

.bg-opacity-40{
    --tw-bg-opacity: 0.4;
}

.bg-opacity-75{
    --tw-bg-opacity: 0.75;
}

.bg-\[radial-gradient\(circle\2c transparent_0\%\2c transparent_0\.6rem\2c theme\(colors\.gray\.300\)_0\.6rem\2c theme\(colors\.gray\.300\)_100\%\)\]{
    background-image: radial-gradient(circle,transparent 0%,transparent 0.6rem,#C8C8C8 0.6rem,#C8C8C8 100%);
}

.bg-\[url\(\'\/bg\.svg\'\)\]{
    background-image: url('/bg.svg');
}

.bg-\[url\(\'\/locate\.svg\'\)\]{
    background-image: url('/locate.svg');
}

.bg-gradient-to-t{
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--tw-gradient-stops)));
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-gray-800{
    --tw-gradient-from: #1F1C20 var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(31, 28, 32, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-orange-600{
    --tw-gradient-to: #ea580c var(--tw-gradient-to-position);
}

.bg-\[length\:100\%_100vh\]{
    background-size: 100% 100vh;
}

.bg-contain{
    background-size: contain;
}

.bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text;
}

.bg-bottom{
    background-position: bottom;
}

.bg-no-repeat{
    background-repeat: no-repeat;
}

.fill-background-group{
    fill: #FFFFFF;
}

.fill-black{
    fill: #000;
}

.fill-brand{
    fill: rgb(253, 212, 2);
    fill: rgba(var(--brand, 253 212 2) / 1);
}

.fill-gray{
    fill: #8B8B8B;
}

.fill-gray-200{
    fill: #CFCFCF;
}

.fill-gray-300{
    fill: #C8C8C8;
}

.fill-gray-50{
    fill: #f9fafb;
}

.fill-transparent{
    fill: transparent;
}

.fill-white{
    fill: #fff;
}

.fill-yellow-300{
    fill: #fde047;
}

.stroke-background-light{
    stroke: #F6F6F6;
}

.stroke-brand{
    stroke: rgb(253, 212, 2);
    stroke: rgba(var(--brand, 253 212 2) / 1);
}

.stroke-gray-800{
    stroke: #1F1C20;
}

.stroke-\[0\.1rem\]{
    stroke-width: 0.1rem;
}

.object-contain{
    -o-object-fit: contain;
       object-fit: contain;
}

.object-cover{
    -o-object-fit: cover;
       object-fit: cover;
}

.\!p-0{
    padding: 0px !important;
}

.\!p-1{
    padding: 0.25rem !important;
}

.\!p-1\.5{
    padding: 0.375rem !important;
}

.\!p-2{
    padding: 0.5rem !important;
}

.p-0{
    padding: 0px;
}

.p-0\.5{
    padding: 0.125rem;
}

.p-1{
    padding: 0.25rem;
}

.p-1\.5{
    padding: 0.375rem;
}

.p-10{
    padding: 2.5rem;
}

.p-2{
    padding: 0.5rem;
}

.p-2\.5{
    padding: 0.625rem;
}

.p-24{
    padding: 6rem;
}

.p-3{
    padding: 0.75rem;
}

.p-4{
    padding: 1rem;
}

.p-5{
    padding: 1.25rem;
}

.p-6{
    padding: 1.5rem;
}

.p-7{
    padding: 1.75rem;
}

.p-8{
    padding: 2rem;
}

.\!px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.\!px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
}

.\!px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.\!px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.\!py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.\!py-\[0\.70rem\]{
    padding-top: 0.70rem !important;
    padding-bottom: 0.70rem !important;
}

.px-0{
    padding-left: 0px;
    padding-right: 0px;
}

.px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.px-4{
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.px-8{
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-0{
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-\[0\.3125rem\]{
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.\!pr-10{
    padding-right: 2.5rem !important;
}

.\!pr-12{
    padding-right: 3rem !important;
}

.\!pr-20{
    padding-right: 5rem !important;
}

.\!pt-8{
    padding-top: 2rem !important;
}

.\!pt-\[10\.4px\]{
    padding-top: 10.4px !important;
}

.pb-1{
    padding-bottom: 0.25rem;
}

.pb-10{
    padding-bottom: 2.5rem;
}

.pb-2{
    padding-bottom: 0.5rem;
}

.pb-3{
    padding-bottom: 0.75rem;
}

.pb-4{
    padding-bottom: 1rem;
}

.pb-6{
    padding-bottom: 1.5rem;
}

.pb-8{
    padding-bottom: 2rem;
}

.pl-12{
    padding-left: 3rem;
}

.pl-16{
    padding-left: 4rem;
}

.pl-2{
    padding-left: 0.5rem;
}

.pl-32{
    padding-left: 8rem;
}

.pl-5{
    padding-left: 1.25rem;
}

.pl-6{
    padding-left: 1.5rem;
}

.pl-7{
    padding-left: 1.75rem;
}

.pl-8{
    padding-left: 2rem;
}

.pr-12{
    padding-right: 3rem;
}

.pr-2{
    padding-right: 0.5rem;
}

.pr-3{
    padding-right: 0.75rem;
}

.pr-4{
    padding-right: 1rem;
}

.pr-5{
    padding-right: 1.25rem;
}

.pr-6{
    padding-right: 1.5rem;
}

.pr-8{
    padding-right: 2rem;
}

.pt-0{
    padding-top: 0px;
}

.pt-0\.5{
    padding-top: 0.125rem;
}

.pt-1{
    padding-top: 0.25rem;
}

.pt-1\.5{
    padding-top: 0.375rem;
}

.pt-10{
    padding-top: 2.5rem;
}

.pt-2{
    padding-top: 0.5rem;
}

.pt-24{
    padding-top: 6rem;
}

.pt-3{
    padding-top: 0.75rem;
}

.pt-4{
    padding-top: 1rem;
}

.pt-5{
    padding-top: 1.25rem;
}

.pt-6{
    padding-top: 1.5rem;
}

.pt-8{
    padding-top: 2rem;
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.text-justify{
    text-align: justify;
}

.text-start{
    text-align: left;
}

.text-end{
    text-align: right;
}

.align-middle{
    vertical-align: middle;
}

.font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans{
    font-family: var(--font-open-sans), Open Sans, sans-serif;
}

.\!text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.\!text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}

.text-2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.text-\[0\.5rem\]{
    font-size: 0.5rem;
}

.text-\[0\.7em\]{
    font-size: 0.7em;
}

.text-\[15px\]{
    font-size: 15px;
}

.text-\[8px\]{
    font-size: 8px;
}

.text-base{
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-xs{
    font-size: 0.75rem;
    line-height: 1rem;
}

.font-black{
    font-weight: 900;
}

.font-bold{
    font-weight: 700;
}

.font-extrabold{
    font-weight: 800;
}

.font-medium{
    font-weight: 500;
}

.font-normal{
    font-weight: 400;
}

.font-semibold{
    font-weight: 600;
}

.uppercase{
    text-transform: uppercase;
}

.capitalize{
    text-transform: capitalize;
}

.italic{
    font-style: italic;
}

.not-italic{
    font-style: normal;
}

.leading-3{
    line-height: .75rem;
}

.leading-5{
    line-height: 1.25rem;
}

.leading-6{
    line-height: 1.5rem;
}

.leading-\[1\.2em\]{
    line-height: 1.2em;
}

.leading-none{
    line-height: 1;
}

.leading-normal{
    line-height: 1.5;
}

.leading-snug{
    line-height: 1.375;
}

.tracking-widest{
    letter-spacing: 0.1em;
}

.\!text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, 1) !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-background-100{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}

.text-background-200{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}

.text-background-300{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}

.text-background-400{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}

.text-background-dark{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}

.text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, 1);
    color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, 1);
    color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, 1);
    color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, 1);
    color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, 1);
    color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, 1);
    color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.text-brand{
    --tw-text-opacity: 1;
    color: rgb(253, 212, 2);
    color: rgba(var(--brand, 253 212 2) / var(--tw-text-opacity));
}

.text-current{
    color: currentColor;
}

.text-danger{
    --tw-text-opacity: 1;
    color: rgba(223, 41, 53, 1);
    color: rgba(223, 41, 53, var(--tw-text-opacity));
}

.text-gray{
    --tw-text-opacity: 1;
    color: rgba(139, 139, 139, 1);
    color: rgba(139, 139, 139, var(--tw-text-opacity));
}

.text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(219, 219, 219, 1);
    color: rgba(219, 219, 219, var(--tw-text-opacity));
}

.text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(207, 207, 207, 1);
    color: rgba(207, 207, 207, var(--tw-text-opacity));
}

.text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(200, 200, 200, 1);
    color: rgba(200, 200, 200, var(--tw-text-opacity));
}

.text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}

.text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, 1);
    color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}

.text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}

.text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}

.text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}

.text-gray-800\/50{
    color: rgba(31, 28, 32, 0.5);
}

.text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(7, 3, 8, 1);
    color: rgba(7, 3, 8, var(--tw-text-opacity));
}

.text-green-500{
    --tw-text-opacity: 1;
    color: rgba(34, 197, 94, 1);
    color: rgba(34, 197, 94, var(--tw-text-opacity));
}

.text-green-600{
    --tw-text-opacity: 1;
    color: rgba(22, 163, 74, 1);
    color: rgba(22, 163, 74, var(--tw-text-opacity));
}

.text-green-700{
    --tw-text-opacity: 1;
    color: rgba(21, 128, 61, 1);
    color: rgba(21, 128, 61, var(--tw-text-opacity));
}

.text-green-800{
    --tw-text-opacity: 1;
    color: rgba(22, 101, 52, 1);
    color: rgba(22, 101, 52, var(--tw-text-opacity));
}

.text-green-900{
    --tw-text-opacity: 1;
    color: rgba(20, 83, 45, 1);
    color: rgba(20, 83, 45, var(--tw-text-opacity));
}

.text-neutral-400{
    --tw-text-opacity: 1;
    color: rgba(163, 163, 163, 1);
    color: rgba(163, 163, 163, var(--tw-text-opacity));
}

.text-orange-500{
    --tw-text-opacity: 1;
    color: rgba(249, 115, 22, 1);
    color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-orange-800{
    --tw-text-opacity: 1;
    color: rgba(154, 52, 18, 1);
    color: rgba(154, 52, 18, var(--tw-text-opacity));
}

.text-primary{
    --tw-text-opacity: 1;
    color: rgba(13, 3, 252, 1);
    color: rgba(13, 3, 252, var(--tw-text-opacity));
}

.text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, 1);
    color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, 1);
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, 1);
    color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, 1);
    color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, 1);
    color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, 1);
    color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.text-secondary-variant-dark{
    --tw-text-opacity: 1;
    color: rgba(53, 67, 57, 1);
    color: rgba(53, 67, 57, var(--tw-text-opacity));
}

.text-success{
    --tw-text-opacity: 1;
    color: rgba(0, 129, 72, 1);
    color: rgba(0, 129, 72, var(--tw-text-opacity));
}

.text-transparent{
    color: transparent;
}

.text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(250, 204, 21, 1);
    color: rgba(250, 204, 21, var(--tw-text-opacity));
}

.text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(234, 179, 8, 1);
    color: rgba(234, 179, 8, var(--tw-text-opacity));
}

.text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(133, 77, 14, 1);
    color: rgba(133, 77, 14, var(--tw-text-opacity));
}

.text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(113, 63, 18, 1);
    color: rgba(113, 63, 18, var(--tw-text-opacity));
}

.text-zinc-500{
    --tw-text-opacity: 1;
    color: rgba(113, 113, 122, 1);
    color: rgba(113, 113, 122, var(--tw-text-opacity));
}

.underline{
    text-decoration-line: underline;
}

.line-through{
    text-decoration-line: line-through;
}

.no-underline{
    text-decoration-line: none;
}

.decoration-brand{
    text-decoration-color: rgb(253, 212, 2);
    text-decoration-color: rgba(var(--brand, 253 212 2) / 1);
}

.decoration-gray-800{
    text-decoration-color: #1F1C20;
}

.decoration-green-600{
    text-decoration-color: #16a34a;
}

.decoration-red-600{
    text-decoration-color: #dc2626;
}

.decoration-2{
    text-decoration-thickness: 2px;
}

.caret-black{
    caret-color: #000;
}

.accent-black{
    accent-color: #000;
}

.accent-brand{
    accent-color: rgb(253, 212, 2);
    accent-color: rgba(var(--brand, 253 212 2) / 1);
}

.opacity-0{
    opacity: 0;
}

.opacity-100{
    opacity: 1;
}

.opacity-25{
    opacity: 0.25;
}

.opacity-30{
    opacity: 0.3;
}

.opacity-50{
    opacity: 0.5;
}

.opacity-65{
    opacity: 0.65;
}

.opacity-70{
    opacity: 0.7;
}

.opacity-75{
    opacity: 0.75;
}

.opacity-80{
    opacity: 0.8;
}

.opacity-90{
    opacity: 0.9;
}

.shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[0_1px_0\]{
    --tw-shadow: 0 1px 0;
    --tw-shadow-colored: 0 1px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 0;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[0_2px_10px\]{
    --tw-shadow: 0 2px 10px;
    --tw-shadow-colored: 0 2px 10px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 2px 10px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[0px_10px_38px_-10px_rgba\(22\2c _23\2c _24\2c _0\.35\)\2c _0px_10px_20px_-15px_rgba\(22\2c _23\2c _24\2c _0\.2\)\]{
    --tw-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    --tw-shadow-colored: 0px 10px 38px -10px var(--tw-shadow-color), 0px 10px 20px -15px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[hsl\(206_22\%_7\%_\/_35\%\)_0px_10px_38px_-10px\2c hsl\(206_22\%_7\%_\/_20\%\)_0px_10px_20px_-15px\]{
    --tw-shadow: hsla(206, 22%, 7%, 0.35) 0px 10px 38px -10px,hsla(206, 22%, 7%, 0.2) 0px 10px 20px -15px;
    --tw-shadow-colored: 0px 10px 38px -10px var(--tw-shadow-color), 0px 10px 20px -15px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), hsla(206, 22%, 7%, 0.35) 0px 10px 38px -10px,hsla(206, 22%, 7%, 0.2) 0px 10px 20px -15px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[inset_0_-3px_0_0_theme\(colors\.brand\.DEFAULT\)\]{
    --tw-shadow: inset 0 -3px 0 0 rgba(var(--brand, 253 212 2) / 1);
    --tw-shadow-colored: inset 0 -3px 0 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 0 -3px 0 0 rgb(253, 212, 2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[inset_0px_-6px_0px_-3px_theme\(colors\.brand\.DEFAULT\)\]{
    --tw-shadow: inset 0px -6px 0px -3px rgba(var(--brand, 253 212 2) / 1);
    --tw-shadow-colored: inset 0px -6px 0px -3px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 0px -6px 0px -3px rgb(253, 212, 2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[inset_0px_0px_0px_0\.075em\]{
    --tw-shadow: inset 0px 0px 0px 0.075em;
    --tw-shadow-colored: inset 0px 0px 0px 0.075em var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 0px 0px 0px 0.075em;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[inset_0px_0px_0px_1\.5px_theme\(colors\.red\.300\)\]{
    --tw-shadow: inset 0px 0px 0px 1.5px #fca5a5;
    --tw-shadow-colored: inset 0px 0px 0px 1.5px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 0px 0px 0px 1.5px #fca5a5;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-\[inset_8px_0px_0px_-4px\]{
    --tw-shadow: inset 8px 0px 0px -4px;
    --tw-shadow-colored: inset 8px 0px 0px -4px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 8px 0px 0px -4px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-none{
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.shadow-black\/10{
    --tw-shadow-color: rgba(0, 0, 0, 0.1);
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/5{
    --tw-shadow-color: rgba(0, 0, 0, 0.05);
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-brand{
    --tw-shadow-color: rgba(var(--brand, 253 212 2) / 1);
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400{
    --tw-shadow-color: #B0AFB0;
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600{
    --tw-shadow-color: #4F4D50;
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700{
    --tw-shadow-color: #373438;
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white{
    --tw-shadow-color: #fff;
    --tw-shadow: var(--tw-shadow-colored);
}

.outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.outline{
    outline-style: solid;
}

.outline-1{
    outline-width: 1px;
}

.outline-2{
    outline-width: 2px;
}

.-outline-offset-1{
    outline-offset: -1px;
}

.outline-offset-0{
    outline-offset: 0px;
}

.outline-brand{
    outline-color: rgb(253, 212, 2);
    outline-color: rgba(var(--brand, 253 212 2) / 1);
}

.outline-gray-500{
    outline-color: #686668;
}

.outline-red-400{
    outline-color: #f87171;
}

.ring-brand-focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(var(--brand-focus, 253 224 71) / var(--tw-ring-opacity));
}

.ring-offset-white{
    --tw-ring-offset-color: #fff;
}

.blur{
    --tw-blur: blur(8px);
    filter: blur(8px) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg{
    --tw-blur: blur(16px);
    filter: blur(16px) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-0{
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) brightness(0) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[0_0_2px_black\]{
    --tw-drop-shadow: drop-shadow(0 0 2px black);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 0 2px black);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale{
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) grayscale(100%) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert{
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) invert(100%) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-0{
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) saturate(0) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-50{
    --tw-saturate: saturate(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) saturate(.5) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: blur(16px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: blur(16px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: blur(12px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: blur(12px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: blur(4px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: blur(4px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) contrast(.5) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) contrast(.5) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-\[height\]{
    transition-property: height;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-\[padding\]{
    transition-property: padding;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-\[width\2c opacity\]{
    transition-property: width,opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-\[width\]{
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-colors{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.delay-0{
    transition-delay: 0s;
}

.delay-100{
    transition-delay: 100ms;
}

.delay-1000{
    transition-delay: 1000ms;
}

.delay-300{
    transition-delay: 300ms;
}

.duration-0{
    transition-duration: 0s;
}

.duration-100{
    transition-duration: 100ms;
}

.duration-1000{
    transition-duration: 1000ms;
}

.duration-10000{
    transition-duration: 10000ms;
}

.duration-200{
    transition-duration: 200ms;
}

.duration-250{
    transition-duration: 250ms;
}

.duration-300{
    transition-duration: 300ms;
}

.duration-500{
    transition-duration: 500ms;
}

.duration-5000{
    transition-duration: 5000ms;
}

.duration-600{
    transition-duration: 600ms;
}

.ease-accordion{
    transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}

.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-linear{
    transition-timing-function: linear;
}

.will-change-\[opacity\2c transform\]{
    will-change: opacity,transform;
}

.will-change-\[opacity\]{
    will-change: opacity;
}

.will-change-transform{
    will-change: transform;
}

@keyframes enter{

    from{
        opacity: 1;
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate(0);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}

@keyframes exit{

    to{
        opacity: 1;
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate(0);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}

.animate-in{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}

.animate-out{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}

.fade-in{
    --tw-enter-opacity: 0;
}

.fade-in-0{
    --tw-enter-opacity: 0;
}

.fade-out-0{
    --tw-exit-opacity: 0;
}

.zoom-in-0{
    --tw-enter-scale: 0;
}

.zoom-out-0{
    --tw-exit-scale: 0;
}

.slide-in-from-bottom{
    --tw-enter-translate-y: 100%;
}

.slide-in-from-left{
    --tw-enter-translate-x: -100%;
}

.slide-in-from-right{
    --tw-enter-translate-x: 100%;
}

.slide-in-from-top{
    --tw-enter-translate-y: -100%;
}

.slide-out-to-right-6{
    --tw-exit-translate-x: 1.5rem;
}

.duration-0{
    animation-duration: 0s;
}

.duration-100{
    animation-duration: 100ms;
}

.duration-1000{
    animation-duration: 1000ms;
}

.duration-10000{
    animation-duration: 10000ms;
}

.duration-200{
    animation-duration: 200ms;
}

.duration-250{
    animation-duration: 250ms;
}

.duration-300{
    animation-duration: 300ms;
}

.duration-500{
    animation-duration: 500ms;
}

.duration-5000{
    animation-duration: 5000ms;
}

.duration-600{
    animation-duration: 600ms;
}

.delay-0{
    animation-delay: 0s;
}

.delay-100{
    animation-delay: 100ms;
}

.delay-1000{
    animation-delay: 1000ms;
}

.delay-300{
    animation-delay: 300ms;
}

.ease-accordion{
    animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}

.ease-in-out{
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-linear{
    animation-timing-function: linear;
}

.running{
    animation-play-state: running;
}

.fill-mode-backwards{
    animation-fill-mode: backwards;
}

.fill-mode-both{
    animation-fill-mode: both;
}

.repeat-infinite{
    animation-iteration-count: infinite;
}

.\!bg-bg{
    background-color: #F6F6F6 !important;
}

.bg-bg{
    background-color: #F6F6F6;
}

.dark .bg-bg{
    background-color: #1F1C20;
}

.dark .\!bg-bg{
    background-color: #1F1C20 !important;
}

.bg-grp{
    background-color: #FFFFFF;
}

.dark .bg-grp{
    background-color: #373438;
}

.bg-sub{
    background-color: #E2E2E2;
}

.dark .bg-sub{
    background-color: #595959;
}

.h-screen {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
  }

.scroll-thin {
    scrollbar-width: thin;
  }

.no-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

.no-scroll::-webkit-scrollbar {
    display: none;
  }

.circle {
    clip-path: circle(50% at 50% 50%);
  }

input[type='number'].no-arrows::-webkit-inner-spin-button,
  input[type='number'].no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

input[type='number'].no-arrows {
    -moz-appearance: textfield;
  }

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.\[clip-path\:circle\(50\%_at_50\%_50\%\)\]{
    clip-path: circle(50% at 50% 50%);
}

.\[overscroll-behavior\:auto_contain\]{
    overscroll-behavior: auto contain;
}

.\[scrollbar-width\:thin\]{
    scrollbar-width: thin;
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
}

body,
#__next {
  height: 100%;
  width: 100%;
}

body{
    font-family: var(--font-open-sans), Open Sans, sans-serif;
}

a {
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  display: grid;
  grid-template-rows: auto 1fr;
}

#__next > #MainContainer {
  grid-row-start: 2;
}

.grecaptcha-badge {
  display: none !important;
  visibility: visible !important;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  z-index: 999;
}

.grecaptcha-badge.show {
  display: block !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.menu-button-container {
  /* https://alvarotrigo.com/blog/hamburger-menu-css/ */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-button,
.menu-button::before,
.menu-button::after{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}

.dark 
.menu-button::before{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}

.dark 
.menu-button::after{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}

.dark .menu-button{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

.menu-button-container.active .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

.menu-button-container.active .menu-button{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .menu-button-container.active .menu-button{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}

.menu-button-container.active .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.child-w-full > *{
    width: 100%;
}

.row-cursor-auto tbody tr {
  cursor: auto;
}

tbody tr:hover .row-button{
    font-weight: 700;
    text-decoration-line: underline;
}

/* OP debugger */
/* * {
  background-color: hwb(138 1% 69% / 0.1);
} */
.dark .dark\:prose-invert{
    --tw-prose-body: var(--tw-prose-invert-body);
    --tw-prose-headings: var(--tw-prose-invert-headings);
    --tw-prose-lead: var(--tw-prose-invert-lead);
    --tw-prose-links: var(--tw-prose-invert-links);
    --tw-prose-bold: var(--tw-prose-invert-bold);
    --tw-prose-counters: var(--tw-prose-invert-counters);
    --tw-prose-bullets: var(--tw-prose-invert-bullets);
    --tw-prose-hr: var(--tw-prose-invert-hr);
    --tw-prose-quotes: var(--tw-prose-invert-quotes);
    --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
    --tw-prose-captions: var(--tw-prose-invert-captions);
    --tw-prose-kbd: var(--tw-prose-invert-kbd);
    --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
    --tw-prose-code: var(--tw-prose-invert-code);
    --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
    --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
    --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
    --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}
.\*\:mb-2 > *{
    margin-bottom: 0.5rem;
}
.\*\:mt-4 > *{
    margin-top: 1rem;
}
.\*\:max-h-32 > *{
    max-height: 8rem;
}
.\*\:shrink-0 > *{
    flex-shrink: 0;
}
.\*\:break-inside-avoid-column > *{
    -moz-column-break-inside: avoid;
         break-inside: avoid-column;
}
.\*\:overflow-auto > *{
    overflow: auto;
}
.\*\:rounded > *{
    border-radius: 0.25rem;
}
.\*\:rounded-none > *{
    border-radius: 0px;
}
.\*\:border-r-2 > *{
    border-right-width: 2px;
}
.\*\:border-background-group > *{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.\*\:bg-background-group > *{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.\*\:p-4 > *{
    padding: 1rem;
}
.before\:absolute::before{
    content: var(--tw-content);
    position: absolute;
}
.before\:-top-0::before{
    content: var(--tw-content);
    top: -0px;
}
.before\:-top-0\.5::before{
    content: var(--tw-content);
    top: -0.125rem;
}
.before\:bottom-0::before{
    content: var(--tw-content);
    bottom: 0px;
}
.before\:bottom-0\.5::before{
    content: var(--tw-content);
    bottom: 0.125rem;
}
.before\:bottom-1\/2::before{
    content: var(--tw-content);
    bottom: 50%;
}
.before\:left-0::before{
    content: var(--tw-content);
    left: 0px;
}
.before\:left-1::before{
    content: var(--tw-content);
    left: 0.25rem;
}
.before\:left-1\/2::before{
    content: var(--tw-content);
    left: 50%;
}
.before\:left-1\/3::before{
    content: var(--tw-content);
    left: 33.333333%;
}
.before\:right-\[3px\]::before{
    content: var(--tw-content);
    right: 3px;
}
.before\:top-0::before{
    content: var(--tw-content);
    top: 0px;
}
.before\:top-1::before{
    content: var(--tw-content);
    top: 0.25rem;
}
.before\:top-1\/2::before{
    content: var(--tw-content);
    top: 50%;
}
.before\:top-1\/3::before{
    content: var(--tw-content);
    top: 33.333333%;
}
.before\:-z-10::before{
    content: var(--tw-content);
    z-index: -10;
}
.before\:z-10::before{
    content: var(--tw-content);
    z-index: 10;
}
.before\:h-0::before{
    content: var(--tw-content);
    height: 0px;
}
.before\:h-0\.5::before{
    content: var(--tw-content);
    height: 0.125rem;
}
.before\:h-1::before{
    content: var(--tw-content);
    height: 0.25rem;
}
.before\:h-1\/2::before{
    content: var(--tw-content);
    height: 50%;
}
.before\:h-3::before{
    content: var(--tw-content);
    height: 0.75rem;
}
.before\:h-4::before{
    content: var(--tw-content);
    height: 1rem;
}
.before\:h-\[1px\]::before{
    content: var(--tw-content);
    height: 1px;
}
.before\:h-full::before{
    content: var(--tw-content);
    height: 100%;
}
.before\:w-1\/2::before{
    content: var(--tw-content);
    width: 50%;
}
.before\:w-1\/3::before{
    content: var(--tw-content);
    width: 33.333333%;
}
.before\:w-2::before{
    content: var(--tw-content);
    width: 0.5rem;
}
.before\:w-3::before{
    content: var(--tw-content);
    width: 0.75rem;
}
.before\:w-4::before{
    content: var(--tw-content);
    width: 1rem;
}
.before\:w-6::before{
    content: var(--tw-content);
    width: 1.5rem;
}
.before\:w-full::before{
    content: var(--tw-content);
    width: 100%;
}
.before\:-translate-x-1\/2::before{
    content: var(--tw-content);
    --tw-translate-x: -50%;
    transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:-translate-y-1\/2::before{
    content: var(--tw-content);
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:translate-y-1\/2::before{
    content: var(--tw-content);
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), 50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:-rotate-45::before{
    content: var(--tw-content);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes ping{

    75%, 100%{
        content: var(--tw-content);
        transform: scale(2);
        opacity: 0;
    }
}
.before\:animate-ping::before{
    content: var(--tw-content);
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.before\:rounded::before{
    content: var(--tw-content);
    border-radius: 0.25rem;
}
.before\:rounded-full::before{
    content: var(--tw-content);
    border-radius: 9999px;
}
.before\:rounded-l::before{
    content: var(--tw-content);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.before\:border-b-2::before{
    content: var(--tw-content);
    border-bottom-width: 2px;
}
.before\:border-l-2::before{
    content: var(--tw-content);
    border-left-width: 2px;
}
.before\:border-brand::before{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.before\:bg-\[\#fbfbfb\]::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 251, 1);
    background-color: rgba(251, 251, 251, var(--tw-bg-opacity));
}
.before\:bg-brand::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.before\:bg-gray-200::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.before\:bg-gray-300::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}
.before\:bg-gray-700::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.before\:bg-white::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.before\:delay-100::before{
    content: var(--tw-content);
    transition-delay: 100ms;
}
.before\:duration-300::before{
    content: var(--tw-content);
    transition-duration: 300ms;
}
.before\:animate-in::before{
    content: var(--tw-content);
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.before\:fade-in-0::before{
    content: var(--tw-content);
    --tw-enter-opacity: 0;
}
.before\:duration-300::before{
    content: var(--tw-content);
    animation-duration: 300ms;
}
.before\:delay-100::before{
    content: var(--tw-content);
    animation-delay: 100ms;
}
.after\:absolute::after{
    content: var(--tw-content);
    position: absolute;
}
.after\:-bottom-1::after{
    content: var(--tw-content);
    bottom: -0.25rem;
}
.after\:left-0::after{
    content: var(--tw-content);
    left: 0px;
}
.after\:left-1\/2::after{
    content: var(--tw-content);
    left: 50%;
}
.after\:left-3::after{
    content: var(--tw-content);
    left: 0.75rem;
}
.after\:left-\[7px\]::after{
    content: var(--tw-content);
    left: 7px;
}
.after\:top-0::after{
    content: var(--tw-content);
    top: 0px;
}
.after\:top-4::after{
    content: var(--tw-content);
    top: 1rem;
}
.after\:top-\[-7px\]::after{
    content: var(--tw-content);
    top: -7px;
}
.after\:top-\[10px\]::after{
    content: var(--tw-content);
    top: 10px;
}
.after\:-z-10::after{
    content: var(--tw-content);
    z-index: -10;
}
.after\:box-border::after{
    content: var(--tw-content);
    box-sizing: border-box;
}
.after\:block::after{
    content: var(--tw-content);
    display: block;
}
.after\:h-1::after{
    content: var(--tw-content);
    height: 0.25rem;
}
.after\:h-2::after{
    content: var(--tw-content);
    height: 0.5rem;
}
.after\:h-4::after{
    content: var(--tw-content);
    height: 1rem;
}
.after\:h-5::after{
    content: var(--tw-content);
    height: 1.25rem;
}
.after\:w-0::after{
    content: var(--tw-content);
    width: 0px;
}
.after\:w-0\.5::after{
    content: var(--tw-content);
    width: 0.125rem;
}
.after\:w-5::after{
    content: var(--tw-content);
    width: 1.25rem;
}
.after\:w-\[2px\]::after{
    content: var(--tw-content);
    width: 2px;
}
.after\:w-full::after{
    content: var(--tw-content);
    width: 100%;
}
.after\:min-w-full::after{
    content: var(--tw-content);
    min-width: 100%;
}
.after\:-translate-x-1\/2::after{
    content: var(--tw-content);
    --tw-translate-x: -50%;
    transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:-translate-y-1\/2::after{
    content: var(--tw-content);
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:-rotate-45::after{
    content: var(--tw-content);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:transform::after{
    content: var(--tw-content);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:rounded-\[10px\]::after{
    content: var(--tw-content);
    border-radius: 10px;
}
.after\:rounded-\[3px\]::after{
    content: var(--tw-content);
    border-radius: 3px;
}
.after\:rounded-full::after{
    content: var(--tw-content);
    border-radius: 9999px;
}
.after\:border-t-2::after{
    content: var(--tw-content);
    border-top-width: 2px;
}
.after\:border-dashed::after{
    content: var(--tw-content);
    border-style: dashed;
}
.after\:border-brand::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.after\:bg-brand::after{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.after\:bg-current::after{
    content: var(--tw-content);
    background-color: currentColor;
}
.first\:mt-0:first-child{
    margin-top: 0px;
}
.first\:w-96:first-child{
    width: 24rem;
}
.first\:rounded-l:first-child{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.first\:border-2:first-child{
    border-width: 2px;
}
.first\:border-gray:first-child{
    --tw-border-opacity: 1;
    border-color: rgba(139, 139, 139, 1);
    border-color: rgba(139, 139, 139, var(--tw-border-opacity));
}
.first\:\*\:mt-0 > *:first-child{
    margin-top: 0px;
}
.first\:\*\:rounded-l > *:first-child{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.last\:rounded-r:last-child{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.last\:border-r:last-child{
    border-right-width: 1px;
}
.last\:\*\:mb-0 > *:last-child{
    margin-bottom: 0px;
}
.last\:\*\:rounded-r > *:last-child{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.last\:\*\:border-r-0 > *:last-child{
    border-right-width: 0;
}
.last\:after\:hidden:last-child::after{
    content: var(--tw-content);
    display: none;
}
.only\:visible:only-child{
    visibility: visible;
}
.only\:flex:only-child{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.only\:h-full:only-child{
    height: 100%;
}
.only\:w-full:only-child{
    width: 100%;
}
.only\:items-center:only-child{
    align-items: center;
}
.only\:justify-center:only-child{
    justify-content: center;
}
.only\:p-2:only-child{
    padding: 0.5rem;
}
.odd\:bg-white:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.odd\:bg-zinc-100:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, 1);
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.odd\:\*\:shadow > *:nth-child(odd){
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.even\:bg-gray-light:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(216, 216, 216, 1);
    background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
}
.even\:bg-gray-light-5:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.even\:bg-white:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.even\:bg-zinc-50:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, 1);
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.even\:bg-opacity-40:nth-child(even){
    --tw-bg-opacity: 0.4;
}
.last-of-type\:after\:hidden:last-of-type::after{
    content: var(--tw-content);
    display: none;
}
.empty\:hidden:empty{
    display: none;
}
.focus-within\:relative:focus-within{
    position: relative;
}
.focus-within\:z-10:focus-within{
    z-index: 10;
}
.focus-within\:border-brand:focus-within{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.focus-within\:bg-zinc-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, 1);
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.focus-within\:shadow-\[0_0_0_2px\]:focus-within{
    --tw-shadow: 0 0 0 2px;
    --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 0 2px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.focus-within\:shadow-gray-400:focus-within{
    --tw-shadow-color: #B0AFB0;
    --tw-shadow: var(--tw-shadow-colored);
}
.focus-within\:outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-within\:outline:focus-within{
    outline-style: solid;
}
.focus-within\:outline-1:focus-within{
    outline-width: 1px;
}
.focus-within\:outline-2:focus-within{
    outline-width: 2px;
}
.focus-within\:outline-brand:focus-within{
    outline-color: rgb(253, 212, 2);
    outline-color: rgba(var(--brand, 253 212 2) / 1);
}
.focus-within\:outline-gray-500:focus-within{
    outline-color: #686668;
}
.focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
}
.focus-within\:delay-0:focus-within{
    transition-delay: 0s;
    animation-delay: 0s;
}
.focus-within\:before\:bg-brand:focus-within::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.focus-within\:before\:bg-green-600:focus-within::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(22, 163, 74, 1);
    background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.focus-within\:before\:delay-0:focus-within::before{
    transition-delay: 0s;
    content: var(--tw-content);
    animation-delay: 0s;
}
.hover\:max-w-full:hover{
    max-width: 100%;
}
.hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem;
    transform: translate(var(--tw-translate-x), -0.5rem) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1.05) scaleY(1.05);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1.1) scaleY(1.1);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:transform:hover{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:rounded:hover{
    border-radius: 0.25rem;
}
.hover\:border-2:hover{
    border-width: 2px;
}
.hover\:border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.hover\:border-brand:hover{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.hover\:border-danger:hover{
    --tw-border-opacity: 1;
    border-color: rgba(223, 41, 53, 1);
    border-color: rgba(223, 41, 53, var(--tw-border-opacity));
}
.hover\:border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(176, 175, 176, 1);
    border-color: rgba(176, 175, 176, var(--tw-border-opacity));
}
.hover\:border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(104, 102, 104, 1);
    border-color: rgba(104, 102, 104, var(--tw-border-opacity));
}
.hover\:bg-background-10:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}
.hover\:bg-background-group:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.hover\:bg-brand:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.hover\:bg-brand-dark:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(221, 186, 2);
    background-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-bg-opacity));
}
.hover\:bg-brand-focus:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 224, 71);
    background-color: rgba(var(--brand-focus, 253 224 71) / var(--tw-bg-opacity));
}
.hover\:bg-danger:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(223, 41, 53, 1);
    background-color: rgba(223, 41, 53, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 219, 219, 1);
    background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.hover\:bg-gray-200\/60:hover{
    background-color: rgba(207, 207, 207, 0.6);
}
.hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}
.hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(176, 175, 176, 1);
    background-color: rgba(176, 175, 176, var(--tw-bg-opacity));
}
.hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.hover\:bg-gray-light:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(216, 216, 216, 1);
    background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
}
.hover\:bg-gray-light-1:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(222, 222, 222, 1);
    background-color: rgba(222, 222, 222, var(--tw-bg-opacity));
}
.hover\:bg-gray-light-2:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(241, 241, 241, 1);
    background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
}
.hover\:bg-gray-light-3:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(225, 224, 255, 1);
    background-color: rgba(225, 224, 255, var(--tw-bg-opacity));
}
.hover\:bg-gray-light-4:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, 1);
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
}
.hover\:bg-gray-light-5:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.hover\:bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.hover\:bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(22, 101, 52, 1);
    background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.hover\:bg-orange-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(234, 88, 12, 1);
    background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.hover\:bg-primary:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(13, 3, 252, 1);
    background-color: rgba(13, 3, 252, var(--tw-bg-opacity));
}
.hover\:bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, 1);
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, 1);
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.hover\:bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, 1);
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, 1);
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.hover\:bg-slate-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, 1);
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.hover\:bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 224, 71, 1);
    background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(250, 204, 21, 1);
    background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(234, 179, 8, 1);
    background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.hover\:bg-zinc-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, 1);
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.hover\:decoration-slice:hover{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
}
.hover\:font-semibold:hover{
    font-weight: 600;
}
.hover\:text-background-light:hover{
    --tw-text-opacity: 1;
    color: rgba(246, 246, 246, 1);
    color: rgba(246, 246, 246, var(--tw-text-opacity));
}
.hover\:text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.hover\:text-brand:hover{
    --tw-text-opacity: 1;
    color: rgb(253, 212, 2);
    color: rgba(var(--brand, 253 212 2) / var(--tw-text-opacity));
}
.hover\:text-brand-dark:hover{
    --tw-text-opacity: 1;
    color: rgb(221, 186, 2);
    color: rgba(var(--brand-dark, 221 186 2) / var(--tw-text-opacity));
}
.hover\:text-brand-focus:hover{
    --tw-text-opacity: 1;
    color: rgb(253, 224, 71);
    color: rgba(var(--brand-focus, 253 224 71) / var(--tw-text-opacity));
}
.hover\:text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.hover\:text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.hover\:text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.hover\:text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.hover\:text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(22, 101, 52, 1);
    color: rgba(22, 101, 52, var(--tw-text-opacity));
}
.hover\:text-primary:hover{
    --tw-text-opacity: 1;
    color: rgba(13, 3, 252, 1);
    color: rgba(13, 3, 252, var(--tw-text-opacity));
}
.hover\:text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, 1);
    color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.hover\:text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:underline:hover{
    text-decoration-line: underline;
}
.hover\:opacity-100:hover{
    opacity: 1;
}
.hover\:opacity-80:hover{
    opacity: 0.8;
}
.hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.hover\:outline-none:hover{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.hover\:ring-1:hover{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.hover\:delay-0:hover{
    transition-delay: 0s;
    animation-delay: 0s;
}
.first\:hover\:border-primary:hover:first-child{
    --tw-border-opacity: 1;
    border-color: rgba(13, 3, 252, 1);
    border-color: rgba(13, 3, 252, var(--tw-border-opacity));
}
.odd\:hover\:bg-gray-light-5:hover:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.odd\:hover\:bg-zinc-100:hover:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, 1);
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.odd\:hover\:bg-zinc-200:hover:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, 1);
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.even\:hover\:bg-gray-light-5:hover:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.even\:hover\:bg-zinc-200:hover:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, 1);
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.focus\:relative:focus{
    position: relative;
}
.focus\:border-0:focus{
    border-width: 0;
}
.focus\:border-2:focus{
    border-width: 2px;
}
.focus\:border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, 1);
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus\:border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(176, 175, 176, 1);
    border-color: rgba(176, 175, 176, var(--tw-border-opacity));
}
.focus\:bg-brand-focus:focus{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 224, 71);
    background-color: rgba(var(--brand-focus, 253 224 71) / var(--tw-bg-opacity));
}
.focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(200, 200, 200, 1);
    background-color: rgba(200, 200, 200, var(--tw-bg-opacity));
}
.focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.focus\:bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, 1);
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.focus\:text-base:focus{
    font-size: 1rem;
    line-height: 1.5rem;
}
.focus\:font-bold:focus{
    font-weight: 700;
}
.focus\:text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.focus\:text-brand:focus{
    --tw-text-opacity: 1;
    color: rgb(253, 212, 2);
    color: rgba(var(--brand, 253 212 2) / var(--tw-text-opacity));
}
.focus\:text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.focus\:text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.focus\:underline:focus{
    text-decoration-line: underline;
}
.focus\:opacity-100:focus{
    opacity: 1;
}
.focus\:shadow-\[0_0_0_2px\]:focus{
    --tw-shadow: 0 0 0 2px;
    --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 0 2px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.focus\:shadow-black:focus{
    --tw-shadow-color: #000;
    --tw-shadow: var(--tw-shadow-colored);
}
.focus\:outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:outline-brand:focus{
    outline-color: rgb(253, 212, 2);
    outline-color: rgba(var(--brand, 253 212 2) / 1);
}
.focus\:outline-gray-200:focus{
    outline-color: #CFCFCF;
}
.focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.focus\:ring-brand:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(var(--brand, 253 212 2) / var(--tw-ring-opacity));
}
.focus\:ring-contrast-light:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 244, 251, var(--tw-ring-opacity));
}
.focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
}
.focus-visible\:outline-0:focus-visible{
    outline-width: 0px;
}
.enabled\:cursor-pointer:enabled{
    cursor: pointer;
}
.enabled\:hover\:bg-white:hover:enabled{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.disabled\:pointer-events-none:disabled{
    pointer-events: none;
}
@keyframes pulse{

    50%{
        opacity: .5;
    }
}
.disabled\:animate-pulse:disabled{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.disabled\:border-red-900:disabled{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, 1);
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.disabled\:bg-brand-disabled:disabled{
    --tw-bg-opacity: 1;
    background-color: rgb(254, 240, 138);
    background-color: rgba(var(--brand-disabled, 254 240 138) / var(--tw-bg-opacity));
}
.disabled\:bg-gray-100:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 219, 219, 1);
    background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}
.disabled\:bg-green-200:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(187, 247, 208, 1);
    background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.disabled\:bg-green-400:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.disabled\:bg-orange-300:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 186, 116, 1);
    background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.disabled\:bg-red-400:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, 1);
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.disabled\:bg-yellow-200:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 240, 138, 1);
    background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.disabled\:text-blue-300:disabled{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, 1);
    color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.disabled\:text-brand-disabled:disabled{
    --tw-text-opacity: 1;
    color: rgb(254, 240, 138);
    color: rgba(var(--brand-disabled, 254 240 138) / var(--tw-text-opacity));
}
.disabled\:text-gray-300:disabled{
    --tw-text-opacity: 1;
    color: rgba(200, 200, 200, 1);
    color: rgba(200, 200, 200, var(--tw-text-opacity));
}
.disabled\:text-gray-400:disabled{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.disabled\:text-gray-500:disabled{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.disabled\:text-gray-600:disabled{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.disabled\:text-green-200:disabled{
    --tw-text-opacity: 1;
    color: rgba(187, 247, 208, 1);
    color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.disabled\:text-red-400:disabled{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, 1);
    color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.disabled\:text-red-900:disabled{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, 1);
    color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.disabled\:opacity-50:disabled{
    opacity: 0.5;
}
.disabled\:opacity-60:disabled{
    opacity: 0.6;
}
.disabled\:opacity-70:disabled{
    opacity: 0.7;
}
.disabled\:hover\:bg-gray-light-5:hover:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.disabled\:hover\:bg-transparent:hover:disabled{
    background-color: transparent;
}
.disabled\:hover\:text-gray-700:hover:disabled{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.group[open] .group-open\:pb-2{
    padding-bottom: 0.5rem;
}
.group[open] .group-open\:pb-4{
    padding-bottom: 1rem;
}
.group:focus-within .group-focus-within\:block{
    display: block;
}
.group:focus-within .group-focus-within\:-rotate-90{
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/lang:focus-within .group-focus-within\/lang\:scale-x-0{
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(0) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/lang:focus-within .group-focus-within\/lang\:scale-x-100{
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:focus-within .group-focus-within\:border-0{
    border-width: 0;
}
.group:focus-within .group-focus-within\:bg-brand{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.group:focus-within .group-focus-within\:text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.group:focus-within .group-focus-within\:underline{
    text-decoration-line: underline;
}
.group\/menu:focus-within .group-focus-within\/menu\:opacity-100{
    opacity: 1;
}
.group:hover .group-hover\:visible{
    visibility: visible;
}
.group:hover .group-hover\:block{
    display: block;
}
.group:hover .group-hover\:flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.group:hover .group-hover\:hidden{
    display: none;
}
.group\/lang:hover .group-hover\/lang\:scale-x-0{
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(0) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/lang:hover .group-hover\/lang\:scale-x-100{
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:bg-brand-dark{
    --tw-bg-opacity: 1;
    background-color: rgb(221, 186, 2);
    background-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-bg-opacity));
}
.group\/rm:hover .group-hover\/rm\:fill-blue-300{
    fill: #93c5fd;
}
.group\/rm:hover .group-hover\/rm\:fill-red-300{
    fill: #fca5a5;
}
.group:hover .group-hover\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.group\/menu:hover .group-hover\/menu\:opacity-100{
    opacity: 1;
}
.group:hover .group-hover\:opacity-100{
    opacity: 1;
}
.group\/rm:focus .group-focus\/rm\:fill-blue-300{
    fill: #93c5fd;
}
.group\/rm:focus .group-focus\/rm\:fill-red-300{
    fill: #fca5a5;
}
.group:focus .group-focus\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.group:focus .group-focus\:underline{
    text-decoration-line: underline;
}
.group:focus .group-focus\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.group:focus .group-focus\:ring-brand{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(var(--brand, 253 212 2) / var(--tw-ring-opacity));
}
.group.danger .group-\[\.danger\]\:border-danger\/30{
    border-color: rgba(223, 41, 53, 0.3);
}
.group.info .group-\[\.info\]\:border-primary\/30{
    border-color: rgba(13, 3, 252, 0.3);
}
.group.success .group-\[\.success\]\:border-success\/30{
    border-color: rgba(0, 129, 72, 0.3);
}
.group.warning .group-\[\.warning\]\:border-yellow-500\/30{
    border-color: rgba(234, 179, 8, 0.3);
}
.group.destructive .group-\[\.destructive\]\:text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, 1);
    color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.group.danger .group-\[\.danger\]\:hover\:border-danger\/30:hover{
    border-color: rgba(223, 41, 53, 0.3);
}
.group.info .group-\[\.info\]\:hover\:border-primary\/30:hover{
    border-color: rgba(13, 3, 252, 0.3);
}
.group.success .group-\[\.success\]\:hover\:border-success\/30:hover{
    border-color: rgba(0, 129, 72, 0.3);
}
.group.warning .group-\[\.warning\]\:hover\:border-yellow-500\/30:hover{
    border-color: rgba(234, 179, 8, 0.3);
}
.group.danger .group-\[\.danger\]\:hover\:bg-danger:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(223, 41, 53, 1);
    background-color: rgba(223, 41, 53, var(--tw-bg-opacity));
}
.group.info .group-\[\.info\]\:hover\:bg-primary:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(13, 3, 252, 1);
    background-color: rgba(13, 3, 252, var(--tw-bg-opacity));
}
.group.success .group-\[\.success\]\:hover\:bg-success:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 129, 72, 1);
    background-color: rgba(0, 129, 72, var(--tw-bg-opacity));
}
.group.warning .group-\[\.warning\]\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(234, 179, 8, 1);
    background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.group.danger .group-\[\.danger\]\:hover\:text-danger-50:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 175, 179, 1);
    color: rgba(243, 175, 179, var(--tw-text-opacity));
}
.group.destructive .group-\[\.destructive\]\:hover\:text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, 1);
    color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.group.info .group-\[\.info\]\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, 1);
    color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.group.success .group-\[\.success\]\:hover\:text-success-50:hover{
    --tw-text-opacity: 1;
    color: rgba(112, 255, 192, 1);
    color: rgba(112, 255, 192, var(--tw-text-opacity));
}
.group.warning .group-\[\.warning\]\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 252, 232, 1);
    color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.group.danger .group-\[\.danger\]\:focus\:ring-danger:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(223, 41, 53, var(--tw-ring-opacity));
}
.group.destructive .group-\[\.destructive\]\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.group.info .group-\[\.info\]\:focus\:ring-primary:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 3, 252, var(--tw-ring-opacity));
}
.group.success .group-\[\.success\]\:focus\:ring-success:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 129, 72, var(--tw-ring-opacity));
}
.group.warning .group-\[\.warning\]\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.group.destructive .group-\[\.destructive\]\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626;
}
.peer:checked ~ .peer-checked\:border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.peer:checked ~ .peer-checked\:bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.peer:checked ~ .peer-checked\:bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(187, 247, 208, 1);
    background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.peer:checked ~ .peer-checked\:outline-white{
    outline-color: #fff;
}
.peer:checked ~ .peer-checked\:before\:translate-x-4::before{
    content: var(--tw-content);
    --tw-translate-x: 1rem;
    transform: translate(1rem, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:checked ~ .peer-checked\:before\:bg-green-600::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(22, 163, 74, 1);
    background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.peer:focus ~ .peer-focus\:shadow-\[0_0_3px_theme\(colors\.brand\.focus\)\]{
    --tw-shadow: 0 0 3px rgba(var(--brand-focus, 253 224 71) / 1);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 0 3px rgb(253, 224, 71);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.peer:disabled ~ .peer-disabled\:bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.peer:disabled ~ .peer-disabled\:bg-transparent{
    background-color: transparent;
}
.peer:disabled ~ .peer-disabled\:text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.peer:disabled ~ .peer-disabled\:opacity-75{
    opacity: 0.75;
}
.data-\[disabled\]\:pointer-events-none[data-disabled]{
    pointer-events: none;
}
.data-\[state\=closed\]\:h-0[data-state=closed]{
    height: 0px;
}
.data-\[swipe\=cancel\]\:translate-x-0[data-swipe=cancel]{
    --tw-translate-x: 0px;
    transform: translate(0px, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=end\]\:translate-x-\[var\(--radix-toast-swipe-end-x\)\][data-swipe=end]{
    --tw-translate-x: var(--radix-toast-swipe-end-x);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=move\]\:translate-x-\[var\(--radix-toast-swipe-move-x\)\][data-swipe=move]{
    --tw-translate-x: var(--radix-toast-swipe-move-x);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes slideUpAndFade{

    0%{
        opacity: 0;
        transform: translateY(2px);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.data-\[side\=bottom\]\:animate-slideUpAndFade[data-side=bottom]{
    animation: slideUpAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
}
@keyframes slideRightAndFade{

    0%{
        opacity: 0;
        transform: translateX(-2px);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.data-\[side\=left\]\:animate-slideRightAndFade[data-side=left]{
    animation: slideRightAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
}
@keyframes slideLeftAndFade{

    0%{
        opacity: 0;
        transform: translateX(2px);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.data-\[side\=right\]\:animate-slideLeftAndFade[data-side=right]{
    animation: slideLeftAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
}
@keyframes slideDownAndFade{

    0%{
        opacity: 0;
        transform: translateY(-2px);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.data-\[side\=top\]\:animate-slideDownAndFade[data-side=top]{
    animation: slideDownAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
}
@keyframes accordion-up{

    from{
        height: var(--radix-accordion-content-height);
    }

    to{
        height: 0;
    }
}
.data-\[state\=closed\]\:animate-accordion-up[data-state=closed]{
    animation: accordion-up 0.2s ease-out;
}
@keyframes collapsible-hide{

    from{
        height: var(--radix-collapsible-content-height);
    }

    to{
        height: 0;
    }
}
.data-\[state\=closed\]\:animate-collapsible-hide[data-state=closed]{
    animation: collapsible-hide 0.3s ease-out;
}
@keyframes fadeOut{

    0%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}
.data-\[state\=closed\]\:animate-fadeOut[data-state=closed]{
    animation: fadeOut 300ms cubic-bezier(0.16, 0, 0.13, 1);
}
@keyframes slideUp{

    from{
        height: var(--radix-accordion-content-height);
    }

    to{
        height: 0;
    }
}
.data-\[state\=closed\]\:animate-slideUp[data-state=closed]{
    animation: slideUp 0.3s ease-out;
}
@keyframes fade-in-down{

    0%{
        opacity: 0;
        transform: translateY(-2px);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.data-\[state\=delayed-open\]\:data-\[side\=top\]\:animate-fade-in-down[data-side=top][data-state=delayed-open]{
    animation: fade-in-down 0.5s ease-out;
}
@keyframes accordion-down{

    from{
        height: 0;
    }

    to{
        height: var(--radix-accordion-content-height);
    }
}
.data-\[state\=open\]\:animate-accordion-down[data-state=open]{
    animation: accordion-down 0.2s ease-out;
}
@keyframes collapsible-show{

    from{
        height: 0;
    }

    to{
        height: var(--radix-collapsible-content-height);
    }
}
.data-\[state\=open\]\:animate-collapsible-show[data-state=open]{
    animation: collapsible-show 0.3s ease-out;
}
@keyframes slideDown{

    from{
        height: 0;
    }

    to{
        height: var(--radix-accordion-content-height);
    }
}
.data-\[state\=open\]\:animate-slideDown[data-state=open]{
    animation: slideDown 0.3s ease-out;
}
.data-\[state\=on\]\:border-brand[data-state=on]{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.data-\[active\]\:bg-brand[data-active]{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.data-\[highlighted\]\:bg-black[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.data-\[highlighted\]\:bg-brand[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.data-\[highlighted\]\:bg-green-700[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgba(21, 128, 61, 1);
    background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.data-\[highlighted\]\:bg-orange-500[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 115, 22, 1);
    background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.data-\[highlighted\]\:bg-red-600[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, 1);
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.data-\[state\=on\]\:bg-brand[data-state=on]{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.data-\[state\=open\]\:bg-brand[data-state=open]{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.data-\[state\=open\]\:bg-brand-focus[data-state=open]{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 224, 71);
    background-color: rgba(var(--brand-focus, 253 224 71) / var(--tw-bg-opacity));
}
.data-\[state\=open\]\:bg-gray-200[data-state=open]{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.data-\[active\]\:text-black[data-active]{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.data-\[disabled\]\:text-gray-400[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.data-\[disabled\]\:text-green-300[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(134, 239, 172, 1);
    color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.data-\[disabled\]\:text-orange-300[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(253, 186, 116, 1);
    color: rgba(253, 186, 116, var(--tw-text-opacity));
}
.data-\[disabled\]\:text-red-300[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, 1);
    color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.data-\[highlighted\]\:text-gray-700[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.data-\[highlighted\]\:text-gray-800[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.data-\[highlighted\]\:text-white[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.data-\[state\=off\]\:text-gray-500[data-state=off]{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.data-\[state\=open\]\:text-black[data-state=open]{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.data-\[state\=on\]\:shadow-\[inset_16px_0px_0px_-8px_theme\(colors\.brand\.DEFAULT\)\][data-state=on]{
    --tw-shadow: inset 16px 0px 0px -8px rgba(var(--brand, 253 212 2) / 1);
    --tw-shadow-colored: inset 16px 0px 0px -8px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 16px 0px 0px -8px rgb(253, 212, 2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.data-\[highlighted\]\:outline-none[data-highlighted]{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.data-\[state\=open\]\:transition-all[data-state=open]{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.data-\[swipe\=move\]\:transition-none[data-swipe=move]{
    transition-property: none;
}
.data-\[state\=closed\]\:duration-200[data-state=closed]{
    transition-duration: 200ms;
}
.data-\[state\=open\]\:duration-500[data-state=open]{
    transition-duration: 500ms;
}
.data-\[motion\^\=from-\]\:animate-in[data-motion^=from-]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=open\]\:animate-in[data-state=open]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=visible\]\:animate-in[data-state=visible]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[motion\^\=to-\]\:animate-out[data-motion^=to-]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:animate-out[data-state=closed]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=hidden\]\:animate-out[data-state=hidden]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[swipe\=end\]\:animate-out[data-swipe=end]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[motion\^\=from-\]\:fade-in[data-motion^=from-]{
    --tw-enter-opacity: 0;
}
.data-\[motion\^\=to-\]\:fade-out[data-motion^=to-]{
    --tw-exit-opacity: 0;
}
.data-\[state\=closed\]\:fade-out[data-state=closed]{
    --tw-exit-opacity: 0;
}
.data-\[state\=closed\]\:fade-out-80[data-state=closed]{
    --tw-exit-opacity: 0.8;
}
.data-\[state\=hidden\]\:fade-out[data-state=hidden]{
    --tw-exit-opacity: 0;
}
.data-\[state\=open\]\:fade-in[data-state=open]{
    --tw-enter-opacity: 0;
}
.data-\[state\=visible\]\:fade-in[data-state=visible]{
    --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:zoom-out-95[data-state=closed]{
    --tw-exit-scale: .95;
}
.data-\[state\=open\]\:zoom-in-90[data-state=open]{
    --tw-enter-scale: .9;
}
.data-\[motion\=from-end\]\:slide-in-from-right-52[data-motion=from-end]{
    --tw-enter-translate-x: 13rem;
}
.data-\[motion\=from-start\]\:slide-in-from-left-52[data-motion=from-start]{
    --tw-enter-translate-x: -13rem;
}
.data-\[motion\=to-end\]\:slide-out-to-right-52[data-motion=to-end]{
    --tw-exit-translate-x: 13rem;
}
.data-\[motion\=to-start\]\:slide-out-to-left-52[data-motion=to-start]{
    --tw-exit-translate-x: -13rem;
}
.data-\[state\=closed\]\:slide-out-to-right-full[data-state=closed]{
    --tw-exit-translate-x: 100%;
}
.data-\[state\=open\]\:slide-in-from-top-full[data-state=open]{
    --tw-enter-translate-y: -100%;
}
.data-\[state\=closed\]\:duration-200[data-state=closed]{
    animation-duration: 200ms;
}
.data-\[state\=open\]\:duration-500[data-state=open]{
    animation-duration: 500ms;
}
.data-\[state\]\:fill-mode-forwards[data-state]{
    animation-fill-mode: forwards;
}
.data-\[state\=open\]\:before\:absolute[data-state=open]::before{
    content: var(--tw-content);
    position: absolute;
}
.data-\[state\=open\]\:before\:left-0[data-state=open]::before{
    content: var(--tw-content);
    left: 0px;
}
.data-\[state\=open\]\:before\:h-full[data-state=open]::before{
    content: var(--tw-content);
    height: 100%;
}
.data-\[state\=open\]\:before\:w-1[data-state=open]::before{
    content: var(--tw-content);
    width: 0.25rem;
}
.data-\[state\=open\]\:before\:rounded-l[data-state=open]::before{
    content: var(--tw-content);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.data-\[state\=open\]\:before\:bg-brand[data-state=open]::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.data-\[state\=off\]\:hover\:bg-stone-300:hover[data-state=off]{
    --tw-bg-opacity: 1;
    background-color: rgba(214, 211, 209, 1);
    background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.data-\[state\=on\]\:enabled\:hover\:bg-brand-dark:hover:enabled[data-state=on]{
    --tw-bg-opacity: 1;
    background-color: rgb(221, 186, 2);
    background-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-bg-opacity));
}
.data-\[state\=off\]\:enabled\:hover\:text-gray-800:hover:enabled[data-state=off]{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:hidden{
    display: none;
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/person[data-state=open] .group-data-\[state\=open\]\/person\:rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-state=open] .group-data-\[state\=open\]\:-rotate-90{
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(-90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-state=open] .group-data-\[state\=open\]\:rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-state=open] .group-data-\[state\=open\]\:rotate-90{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:grid-cols-\[1fr\]{
    grid-template-columns: 1fr;
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:justify-center{
    justify-content: center;
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:overflow-visible{
    overflow: visible;
}
.group[data-selected] .group-data-\[selected\]\:border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.group[data-selected] .group-data-\[selected\]\:bg-brand{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.group[data-disabled] .group-data-\[disabled\]\:text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.group[data-highlighted] .group-data-\[highlighted\]\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.group[data-selected] .group-data-\[selected\]\:text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
@supports (gap: var(--tw)){

    .supports-\[gap\]\:-mt-4{
        margin-top: -1rem;
    }
}
@media (prefers-reduced-motion: no-preference){

    @keyframes ping{

        75%, 100%{
            transform: scale(2);
            opacity: 0;
        }
    }

    .motion-safe\:animate-\[ping_1\.5s_cubic-bezier\(0\2c 0\2c 0\.2\2c 1\)_infinite\]{
        animation: ping 1.5s cubic-bezier(0,0,0.2,1) infinite;
    }

    @keyframes slideUpAndFade{

        0%{
            opacity: 0;
            transform: translateY(2px);
        }

        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }

    .motion-safe\:animate-slideUpAndFade{
        animation: slideUpAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
    }

    .motion-safe\:slide-in-from-bottom-4{
        --tw-enter-translate-y: 1rem;
    }

    .motion-safe\:slide-in-from-bottom-full{
        --tw-enter-translate-y: 100%;
    }

    .motion-safe\:focus-within\:-mx-4:focus-within{
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .hover\:motion-safe\:-translate-y-\[1px\]:hover{
        --tw-translate-y: -1px;
        transform: translate(var(--tw-translate-x), -1px) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    @keyframes slideDownAndFadeOut{

        0%{
            opacity: 1;
            transform: translateY(-2px);
        }

        100%{
            opacity: 0;
            transform: translateY(0);
        }
    }

    .data-\[state\=closed\]\:motion-safe\:animate-slideDownAndFadeOut[data-state=closed]{
        animation: slideDownAndFadeOut 300ms cubic-bezier(0.16, 0, 0.13, 1);
    }

    @keyframes fadeIn{

        0%{
            opacity: 0;
        }

        100%{
            opacity: 1;
        }
    }

    .data-\[state\=open\]\:motion-safe\:animate-fadeIn[data-state=open]{
        animation: fadeIn 300ms cubic-bezier(0.16, 0, 0.13, 1);
    }

    @keyframes slideUpAndFade{

        0%{
            opacity: 0;
            transform: translateY(2px);
        }

        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }

    .data-\[state\=open\]\:motion-safe\:animate-slideUpAndFade[data-state=open]{
        animation: slideUpAndFade 300ms cubic-bezier(0.16, 0, 0.13, 1);
    }
}
.dark .dark\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(104, 102, 104, 1);
    border-color: rgba(104, 102, 104, var(--tw-divide-opacity));
}
.dark .dark\:border{
    border-width: 1px;
}
.dark .dark\:border-0{
    border-width: 0;
}
.dark .dark\:border-none{
    border-style: none;
}
.dark .dark\:border-background-dark{
    --tw-border-opacity: 1;
    border-color: rgba(31, 28, 32, 1);
    border-color: rgba(31, 28, 32, var(--tw-border-opacity));
}
.dark .dark\:border-brand-dark{
    --tw-border-opacity: 1;
    border-color: rgb(221, 186, 2);
    border-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-border-opacity));
}
.dark .dark\:border-gray{
    --tw-border-opacity: 1;
    border-color: rgba(139, 139, 139, 1);
    border-color: rgba(139, 139, 139, var(--tw-border-opacity));
}
.dark .dark\:border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(200, 200, 200, 1);
    border-color: rgba(200, 200, 200, var(--tw-border-opacity));
}
.dark .dark\:border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(104, 102, 104, 1);
    border-color: rgba(104, 102, 104, var(--tw-border-opacity));
}
.dark .dark\:border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 77, 80, 1);
    border-color: rgba(79, 77, 80, var(--tw-border-opacity));
}
.dark .dark\:border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 52, 56, 1);
    border-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.dark .dark\:border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 28, 32, 1);
    border-color: rgba(31, 28, 32, var(--tw-border-opacity));
}
.dark .dark\:border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, 1);
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.dark .dark\:border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.dark .dark\:border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(253, 224, 71, 1);
    border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}
.dark .dark\:border-zinc-500{
    --tw-border-opacity: 1;
    border-color: rgba(113, 113, 122, 1);
    border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}
.dark .dark\:border-b-gray-700{
    --tw-border-opacity: 1;
    border-bottom-color: rgba(55, 52, 56, 1);
    border-bottom-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.dark .dark\:border-l-brand-dark{
    --tw-border-opacity: 1;
    border-left-color: rgb(221, 186, 2);
    border-left-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-border-opacity));
}
.dark .dark\:border-t-gray-600{
    --tw-border-opacity: 1;
    border-top-color: rgba(79, 77, 80, 1);
    border-top-color: rgba(79, 77, 80, var(--tw-border-opacity));
}
.dark .dark\:\!bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 77, 80, 1) !important;
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-background-dark{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.dark .dark\:bg-background-group-dark{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, 1);
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.dark .dark\:bg-brand{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.dark .dark\:bg-brand-dark{
    --tw-bg-opacity: 1;
    background-color: rgb(221, 186, 2);
    background-color: rgba(var(--brand-dark, 221 186 2) / var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-400\/25{
    background-color: rgba(176, 175, 176, 0.25);
}
.dark .dark\:bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-500\/25{
    background-color: rgba(104, 102, 104, 0.25);
}
.dark .dark\:bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-700\/50{
    background-color: rgba(55, 52, 56, 0.5);
}
.dark .dark\:bg-gray-700\/80{
    background-color: rgba(55, 52, 56, 0.8);
}
.dark .dark\:bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(7, 3, 8, 1);
    background-color: rgba(7, 3, 8, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-light{
    --tw-bg-opacity: 1;
    background-color: rgba(216, 216, 216, 1);
    background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(21, 128, 61, 1);
    background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(20, 83, 45, 1);
    background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-700{
    --tw-bg-opacity: 1;
    background-color: rgba(194, 65, 12, 1);
    background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, 1);
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, 1);
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, 1);
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-500{
    --tw-bg-opacity: 1;
    background-color: rgba(100, 116, 139, 1);
    background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.dark .dark\:bg-transparent{
    background-color: transparent;
}
.dark .dark\:bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(250, 204, 21, 1);
    background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(133, 77, 14, 1);
    background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 63, 18, 1);
    background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-700{
    --tw-bg-opacity: 1;
    background-color: rgba(63, 63, 70, 1);
    background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.dark .dark\:bg-opacity-40{
    --tw-bg-opacity: 0.4;
}
.dark .dark\:bg-opacity-70{
    --tw-bg-opacity: 0.7;
}
.dark .dark\:bg-\[radial-gradient\(circle\2c transparent_0\%\2c transparent_0\.6rem\2c theme\(colors\.gray\.600\)_0\.6rem\2c theme\(colors\.gray\.600\)_100\%\)\]{
    background-image: radial-gradient(circle,transparent 0%,transparent 0.6rem,#4F4D50 0.6rem,#4F4D50 100%);
}
.dark .dark\:bg-\[url\(\'\/bg-dark\.svg\'\)\]{
    background-image: url('/bg-dark.svg');
}
.dark .dark\:fill-background-dark{
    fill: #1F1C20;
}
.dark .dark\:fill-background-group-dark{
    fill: #373438;
}
.dark .dark\:fill-gray-400{
    fill: #B0AFB0;
}
.dark .dark\:fill-gray-600{
    fill: #4F4D50;
}
.dark .dark\:fill-gray-700{
    fill: #373438;
}
.dark .dark\:fill-gray-800{
    fill: #1F1C20;
}
.dark .dark\:fill-gray-900{
    fill: #070308;
}
.dark .dark\:fill-white{
    fill: #fff;
}
.dark .dark\:stroke-brand{
    stroke: rgb(253, 212, 2);
    stroke: rgba(var(--brand, 253 212 2) / 1);
}
.dark .dark\:stroke-gray-100{
    stroke: #DBDBDB;
}
.dark .dark\:stroke-gray-800{
    stroke: #1F1C20;
}
.dark .dark\:stroke-white{
    stroke: #fff;
}
.dark .dark\:text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.dark .dark\:text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, 1);
    color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.dark .dark\:text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, 1);
    color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.dark .dark\:text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, 1);
    color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.dark .dark\:text-brand{
    --tw-text-opacity: 1;
    color: rgb(253, 212, 2);
    color: rgba(var(--brand, 253 212 2) / var(--tw-text-opacity));
}
.dark .dark\:text-brand-dark{
    --tw-text-opacity: 1;
    color: rgb(221, 186, 2);
    color: rgba(var(--brand-dark, 221 186 2) / var(--tw-text-opacity));
}
.dark .dark\:text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(219, 219, 219, 1);
    color: rgba(219, 219, 219, var(--tw-text-opacity));
}
.dark .dark\:text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(207, 207, 207, 1);
    color: rgba(207, 207, 207, var(--tw-text-opacity));
}
.dark .dark\:text-gray-200\/50{
    color: rgba(207, 207, 207, 0.5);
}
.dark .dark\:text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(200, 200, 200, 1);
    color: rgba(200, 200, 200, var(--tw-text-opacity));
}
.dark .dark\:text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .dark\:text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, 1);
    color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.dark .dark\:text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.dark .dark\:text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .dark\:text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.dark .dark\:text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(7, 3, 8, 1);
    color: rgba(7, 3, 8, var(--tw-text-opacity));
}
.dark .dark\:text-gray-light{
    --tw-text-opacity: 1;
    color: rgba(216, 216, 216, 1);
    color: rgba(216, 216, 216, var(--tw-text-opacity));
}
.dark .dark\:text-gray-light-2{
    --tw-text-opacity: 1;
    color: rgba(241, 241, 241, 1);
    color: rgba(241, 241, 241, var(--tw-text-opacity));
}
.dark .dark\:text-green-100{
    --tw-text-opacity: 1;
    color: rgba(220, 252, 231, 1);
    color: rgba(220, 252, 231, var(--tw-text-opacity));
}
.dark .dark\:text-green-200{
    --tw-text-opacity: 1;
    color: rgba(187, 247, 208, 1);
    color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.dark .dark\:text-green-300{
    --tw-text-opacity: 1;
    color: rgba(134, 239, 172, 1);
    color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.dark .dark\:text-green-400{
    --tw-text-opacity: 1;
    color: rgba(74, 222, 128, 1);
    color: rgba(74, 222, 128, var(--tw-text-opacity));
}
.dark .dark\:text-green-500{
    --tw-text-opacity: 1;
    color: rgba(34, 197, 94, 1);
    color: rgba(34, 197, 94, var(--tw-text-opacity));
}
.dark .dark\:text-green-600{
    --tw-text-opacity: 1;
    color: rgba(22, 163, 74, 1);
    color: rgba(22, 163, 74, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-900{
    --tw-text-opacity: 1;
    color: rgba(23, 23, 23, 1);
    color: rgba(23, 23, 23, var(--tw-text-opacity));
}
.dark .dark\:text-orange-500{
    --tw-text-opacity: 1;
    color: rgba(249, 115, 22, 1);
    color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.dark .dark\:text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, 1);
    color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.dark .dark\:text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, 1);
    color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.dark .dark\:text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, 1);
    color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.dark .dark\:text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, 1);
    color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.dark .dark\:text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, 1);
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.dark .dark\:text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, 1);
    color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.dark .dark\:text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, 1);
    color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.dark .dark\:text-transparent{
    color: transparent;
}
.dark .dark\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 249, 195, 1);
    color: rgba(254, 249, 195, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(253, 224, 71, 1);
    color: rgba(253, 224, 71, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(250, 204, 21, 1);
    color: rgba(250, 204, 21, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(254, 252, 232, 1);
    color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(202, 138, 4, 1);
    color: rgba(202, 138, 4, var(--tw-text-opacity));
}
.dark .dark\:decoration-brand{
    text-decoration-color: rgb(253, 212, 2);
    text-decoration-color: rgba(var(--brand, 253 212 2) / 1);
}
.dark .dark\:decoration-green-400{
    text-decoration-color: #4ade80;
}
.dark .dark\:decoration-red-400{
    text-decoration-color: #f87171;
}
.dark .dark\:caret-white{
    caret-color: #fff;
}
.dark .dark\:opacity-80{
    opacity: 0.8;
}
.dark .dark\:mix-blend-lighten{
    mix-blend-mode: lighten;
}
.dark .dark\:mix-blend-difference{
    mix-blend-mode: difference;
}
.dark .dark\:shadow-\[inset_0px_0px_0px_1\.5px_theme\(colors\.red\.500\)\]{
    --tw-shadow: inset 0px 0px 0px 1.5px #ef4444;
    --tw-shadow-colored: inset 0px 0px 0px 1.5px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), inset 0px 0px 0px 1.5px #ef4444;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.dark .dark\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.dark .dark\:shadow-gray-600{
    --tw-shadow-color: #4F4D50;
    --tw-shadow: var(--tw-shadow-colored);
}
.dark .dark\:shadow-gray-800{
    --tw-shadow-color: #1F1C20;
    --tw-shadow: var(--tw-shadow-colored);
}
.dark .dark\:ring-offset-gray-700{
    --tw-ring-offset-color: #373438;
}
.dark .dark\:brightness-90{
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) brightness(.9) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:drop-shadow-dc{
    --tw-drop-shadow: drop-shadow(0 0 2px rgba(255,255,255,0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 0 2px rgba(255,255,255,0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 rgba(0,0,0,0));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 0 rgba(0,0,0,0));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:grayscale{
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) grayscale(100%) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:invert{
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) invert(100%) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:invert-0{
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) invert(0) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:saturate-100{
    --tw-saturate: saturate(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) saturate(1) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .dark\:\*\:border-background-group-dark > *{
    --tw-border-opacity: 1;
    border-color: rgba(55, 52, 56, 1);
    border-color: rgba(55, 52, 56, var(--tw-border-opacity));
}
.dark .dark\:\*\:bg-background-group-dark > *{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:placeholder\:text-gray-400::-moz-placeholder{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .dark\:placeholder\:text-gray-400::placeholder{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .dark\:placeholder\:text-gray-500::-moz-placeholder{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.dark .dark\:placeholder\:text-gray-500::placeholder{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.dark .before\:dark\:bg-gray-700::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .before\:dark\:bg-white::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:before\:bg-gray-600::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .dark\:before\:bg-gray-700::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:before\:bg-gray-800::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.dark .only\:dark\:text-white:only-child{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .odd\:dark\:bg-gray-700:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .odd\:dark\:bg-transparent:nth-child(odd){
    background-color: transparent;
}
.dark .dark\:even\:bg-gray-500:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .even\:dark\:bg-gray-600:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .even\:dark\:bg-gray-700:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .even\:dark\:bg-transparent:nth-child(even){
    background-color: transparent;
}
.dark .dark\:focus-within\:border-brand:focus-within{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.dark .focus-within\:dark\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:focus-within\:outline-white:focus-within{
    outline-color: #fff;
}
.dark .dark\:hover\:border-brand:hover{
    --tw-border-opacity: 1;
    border-color: rgb(253, 212, 2);
    border-color: rgba(var(--brand, 253 212 2) / var(--tw-border-opacity));
}
.dark .dark\:hover\:border-danger:hover{
    --tw-border-opacity: 1;
    border-color: rgba(223, 41, 53, 1);
    border-color: rgba(223, 41, 53, var(--tw-border-opacity));
}
.dark .dark\:hover\:border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, 1);
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.dark .dark\:hover\:bg-brand:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(253, 212, 2);
    background-color: rgba(var(--brand, 253 212 2) / var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-danger:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(223, 41, 53, 1);
    background-color: rgba(223, 41, 53, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(207, 207, 207, 1);
    background-color: rgba(207, 207, 207, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, 1);
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, 1);
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, 1);
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(250, 204, 21, 1);
    background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.dark .hover\:dark\:bg-background-group-dark:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .hover\:dark\:bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .hover\:dark\:bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .dark\:hover\:text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, 1);
    color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-brand-dark:hover{
    --tw-text-opacity: 1;
    color: rgb(221, 186, 2);
    color: rgba(var(--brand-dark, 221 186 2) / var(--tw-text-opacity));
}
.dark .dark\:hover\:text-brand-focus:hover{
    --tw-text-opacity: 1;
    color: rgb(253, 224, 71);
    color: rgba(var(--brand-focus, 253 224 71) / var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, 1);
    color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .hover\:dark\:text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(207, 207, 207, 1);
    color: rgba(207, 207, 207, var(--tw-text-opacity));
}
.dark .dark\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.dark .dark\:hover\:shadow-slate-800:hover{
    --tw-shadow-color: #1e293b;
    --tw-shadow: var(--tw-shadow-colored);
}
.dark .first\:dark\:hover\:border-gray-100:hover:first-child{
    --tw-border-opacity: 1;
    border-color: rgba(219, 219, 219, 1);
    border-color: rgba(219, 219, 219, var(--tw-border-opacity));
}
.dark .odd\:dark\:hover\:bg-gray-500:hover:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .odd\:dark\:hover\:bg-neutral-800:hover:nth-child(odd){
    --tw-bg-opacity: 1;
    background-color: rgba(38, 38, 38, 1);
    background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.dark .even\:dark\:hover\:bg-gray-500:hover:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .even\:dark\:hover\:bg-neutral-700:hover:nth-child(even){
    --tw-bg-opacity: 1;
    background-color: rgba(64, 64, 64, 1);
    background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.dark .dark\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .dark\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 28, 32, 1);
    background-color: rgba(31, 28, 32, var(--tw-bg-opacity));
}
.dark .dark\:focus\:text-brand-focus:focus{
    --tw-text-opacity: 1;
    color: rgb(253, 224, 71);
    color: rgba(var(--brand-focus, 253 224 71) / var(--tw-text-opacity));
}
.dark .enabled\:dark\:hover\:bg-green-400:hover:enabled{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 222, 128, 1);
    background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .enabled\:dark\:hover\:text-white:hover:enabled{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .dark\:disabled\:border-gray-600:disabled{
    --tw-border-opacity: 1;
    border-color: rgba(79, 77, 80, 1);
    border-color: rgba(79, 77, 80, var(--tw-border-opacity));
}
.dark .dark\:disabled\:bg-gray-500:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.dark .dark\:disabled\:bg-gray-600:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 77, 80, 1);
    background-color: rgba(79, 77, 80, var(--tw-bg-opacity));
}
.dark .dark\:disabled\:bg-gray-700:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:disabled\:bg-orange-300:disabled{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 186, 116, 1);
    background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.dark .dark\:disabled\:text-gray-400:disabled{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .dark\:disabled\:text-gray-500:disabled{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.dark .dark\:disabled\:text-gray-600:disabled{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .dark\:disabled\:text-gray-700:disabled{
    --tw-text-opacity: 1;
    color: rgba(55, 52, 56, 1);
    color: rgba(55, 52, 56, var(--tw-text-opacity));
}
.dark .disabled\:dark\:hover\:text-gray-400:hover:disabled{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .group\/card:hover .dark\:group-hover\/card\:stroke-gray-800{
    stroke: #1F1C20;
}
.dark .peer:disabled ~ .dark\:peer-disabled\:text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .peer:disabled ~ .dark\:peer-disabled\:before\:bg-gray-700::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgba(55, 52, 56, 1);
    background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
}
.dark .dark\:data-\[highlighted\]\:bg-white[data-highlighted]{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:data-\[disabled\]\:text-gray-600[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(79, 77, 80, 1);
    color: rgba(79, 77, 80, var(--tw-text-opacity));
}
.dark .dark\:data-\[disabled\]\:text-green-900[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(20, 83, 45, 1);
    color: rgba(20, 83, 45, var(--tw-text-opacity));
}
.dark .dark\:data-\[disabled\]\:text-orange-900[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(124, 45, 18, 1);
    color: rgba(124, 45, 18, var(--tw-text-opacity));
}
.dark .dark\:data-\[disabled\]\:text-red-900[data-disabled]{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, 1);
    color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.dark .dark\:data-\[highlighted\]\:text-black[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.dark .dark\:data-\[highlighted\]\:text-gray-800[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.dark .dark\:data-\[highlighted\]\:text-white[data-highlighted]{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .data-\[state\=off\]\:dark\:text-gray-400[data-state=off]{
    --tw-text-opacity: 1;
    color: rgba(176, 175, 176, 1);
    color: rgba(176, 175, 176, var(--tw-text-opacity));
}
.dark .data-\[state\=off\]\:hover\:dark\:bg-transparent[data-state=off]:hover{
    background-color: transparent;
}
.dark .data-\[state\=off\]\:enabled\:dark\:hover\:text-gray-200[data-state=off]:hover:enabled{
    --tw-text-opacity: 1;
    color: rgba(207, 207, 207, 1);
    color: rgba(207, 207, 207, var(--tw-text-opacity));
}
.dark .group[data-selected] .dark\:group-data-\[selected\]\:border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(207, 207, 207, 1);
    border-color: rgba(207, 207, 207, var(--tw-border-opacity));
}
.dark .group[data-selected] .dark\:group-data-\[selected\]\:text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
@media not all and (min-width: 1024px){

    .max-lg\:order-1{
        order: 1;
    }

    .max-lg\:order-2{
        order: 2;
    }

    .max-lg\:grid-rows-\[35vh_auto\]{
        grid-template-rows: 35vh auto;
    }
}
@media not all and (min-width: 640px){

    .max-sm\:absolute{
        position: absolute;
    }

    .max-sm\:right-0{
        right: 0px;
    }

    .max-sm\:top-full{
        top: 100%;
    }

    .max-sm\:z-20{
        z-index: 20;
    }

    .max-sm\:\!mb-7{
        margin-bottom: 1.75rem !important;
    }

    .max-sm\:flex{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .max-sm\:hidden{
        display: none;
    }

    .max-sm\:max-h-\[50vh\]{
        max-height: 50vh;
    }

    .max-sm\:flex-col{
        flex-direction: column;
    }

    .max-sm\:border-2{
        border-width: 2px;
    }

    .max-sm\:border-gray-300{
        --tw-border-opacity: 1;
        border-color: rgba(200, 200, 200, 1);
        border-color: rgba(200, 200, 200, var(--tw-border-opacity));
    }

    .max-sm\:bg-white{
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    }

    .max-sm\:p-4{
        padding: 1rem;
    }

    .dark .dark\:max-sm\:border-gray-500{
        --tw-border-opacity: 1;
        border-color: rgba(104, 102, 104, 1);
        border-color: rgba(104, 102, 104, var(--tw-border-opacity));
    }

    .dark .dark\:max-sm\:bg-gray-700{
        --tw-bg-opacity: 1;
        background-color: rgba(55, 52, 56, 1);
        background-color: rgba(55, 52, 56, var(--tw-bg-opacity));
    }
}
@media (min-width: 480px){

    .min-\[480px\]\:static{
        position: static;
    }

    .min-\[480px\]\:w-\[min\(70vw\2c 400px\)\]{
        width: min(70vw,400px);
    }
}
@media (min-width: 640px){

    .sm\:not-sr-only{
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }

    .sm\:absolute{
        position: absolute;
    }

    .sm\:bottom-0{
        bottom: 0px;
    }

    .sm\:left-1\/2{
        left: 50%;
    }

    .sm\:right-0{
        right: 0px;
    }

    .sm\:top-auto{
        top: auto;
    }

    .sm\:\!order-first{
        order: -9999 !important;
    }

    .sm\:order-first{
        order: -9999;
    }

    .sm\:order-last{
        order: 9999;
    }

    .sm\:\!col-span-1{
        grid-column: span 1 / span 1 !important;
    }

    .sm\:\!col-span-2{
        grid-column: span 2 / span 2 !important;
    }

    .sm\:\!col-span-3{
        grid-column: span 3 / span 3 !important;
    }

    .sm\:col-span-1{
        grid-column: span 1 / span 1;
    }

    .sm\:col-span-2{
        grid-column: span 2 / span 2;
    }

    .sm\:col-span-3{
        grid-column: span 3 / span 3;
    }

    .sm\:col-span-full{
        grid-column: 1 / -1;
    }

    .sm\:\!col-start-2{
        grid-column-start: 2 !important;
    }

    .sm\:col-start-2{
        grid-column-start: 2;
    }

    .sm\:\!col-end-1{
        grid-column-end: 1 !important;
    }

    .sm\:col-end-\[-1\]{
        grid-column-end: -1;
    }

    .sm\:col-end-\[-4\]{
        grid-column-end: -4;
    }

    .sm\:row-span-1{
        grid-row: span 1 / span 1;
    }

    .sm\:row-start-3{
        grid-row-start: 3;
    }

    .sm\:\!mx-0{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .sm\:mb-5{
        margin-bottom: 1.25rem;
    }

    .sm\:ml-auto{
        margin-left: auto;
    }

    .sm\:\!block{
        display: block !important;
    }

    .sm\:block{
        display: block;
    }

    .sm\:\!inline-block{
        display: inline-block !important;
    }

    .sm\:flex{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .sm\:grid{
        display: grid;
    }

    .sm\:\!contents{
        display: contents !important;
    }

    .sm\:contents{
        display: contents;
    }

    .sm\:hidden{
        display: none;
    }

    .sm\:\!h-40{
        height: 10rem !important;
    }

    .sm\:h-28{
        height: 7rem;
    }

    .sm\:h-9{
        height: 2.25rem;
    }

    .sm\:h-96{
        height: 24rem;
    }

    .sm\:\!max-h-\[45vw\]{
        max-height: 45vw !important;
    }

    .sm\:max-h-\[50vh\]{
        max-height: 50vh;
    }

    .sm\:min-h-\[24rem\]{
        min-height: 24rem;
    }

    .sm\:\!w-1\/2{
        width: 50% !important;
    }

    .sm\:\!w-1\/3{
        width: 33.333333% !important;
    }

    .sm\:\!w-96{
        width: 24rem !important;
    }

    .sm\:w-1\/2{
        width: 50%;
    }

    .sm\:w-1\/3{
        width: 33.333333%;
    }

    .sm\:w-4\/5{
        width: 80%;
    }

    .sm\:w-4\/6{
        width: 66.666667%;
    }

    .sm\:w-60{
        width: 15rem;
    }

    .sm\:w-9{
        width: 2.25rem;
    }

    .sm\:w-96{
        width: 24rem;
    }

    .sm\:w-\[25rem\]{
        width: 25rem;
    }

    .sm\:w-\[30rem\]{
        width: 30rem;
    }

    .sm\:w-\[35rem\]{
        width: 35rem;
    }

    .sm\:w-max{
        width: -moz-max-content;
        width: -webkit-max-content;
        width: max-content;
    }

    .sm\:w-min{
        width: -moz-min-content;
        width: -webkit-min-content;
        width: min-content;
    }

    .sm\:min-w-84{
        min-width: 21rem;
    }

    .sm\:min-w-96{
        min-width: 24rem;
    }

    .sm\:min-w-\[30rem\]{
        min-width: 30rem;
    }

    .sm\:\!max-w-lg{
        max-width: 32rem !important;
    }

    .sm\:max-w-\[50ch\]{
        max-width: 50ch;
    }

    .sm\:max-w-\[80\%\]{
        max-width: 80%;
    }

    .sm\:max-w-md{
        max-width: 28rem;
    }

    .sm\:max-w-xl{
        max-width: 36rem;
    }

    .sm\:flex-grow-0{
        flex-grow: 0;
    }

    .sm\:basis-20{
        flex-basis: 5rem;
    }

    .sm\:basis-24{
        flex-basis: 6rem;
    }

    .sm\:basis-60{
        flex-basis: 15rem;
    }

    .sm\:basis-auto{
        flex-basis: auto;
    }

    .sm\:-translate-x-1\/2{
        --tw-translate-x: -50%;
        transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    .sm\:grid-flow-col{
        grid-auto-flow: column;
    }

    .sm\:\!grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .sm\:\!grid-cols-5{
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }

    .sm\:\!grid-cols-6{
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
    }

    .sm\:\!grid-cols-\[2fr_1fr_1fr\]{
        grid-template-columns: 2fr 1fr 1fr !important;
    }

    .sm\:grid-cols-1{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .sm\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .sm\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .sm\:grid-cols-\[1fr\2c 10ch\2c 12ch\]{
        grid-template-columns: 1fr 10ch 12ch;
    }

    .sm\:grid-cols-\[1fr\2c 5\.5rem\]{
        grid-template-columns: 1fr 5.5rem;
    }

    .sm\:grid-cols-\[1fr_auto\]{
        grid-template-columns: 1fr auto;
    }

    .sm\:grid-cols-\[2\.5rem\2c 8ch\2c auto\]{
        grid-template-columns: 2.5rem 8ch auto;
    }

    .sm\:grid-cols-\[3fr\2c 2fr\]{
        grid-template-columns: 3fr 2fr;
    }

    .sm\:grid-cols-\[4rem_18rem_7rem\]{
        grid-template-columns: 4rem 18rem 7rem;
    }

    .sm\:grid-cols-\[auto\2c 1fr\2c auto\]{
        grid-template-columns: auto 1fr auto;
    }

    .sm\:grid-cols-\[auto\2c 1fr\]{
        grid-template-columns: auto 1fr;
    }

    .sm\:grid-cols-\[auto_1fr\]{
        grid-template-columns: auto 1fr;
    }

    .sm\:grid-cols-\[repeat\(2\2c 16rem\)\]{
        grid-template-columns: repeat(2,16rem);
    }

    .sm\:grid-cols-\[repeat\(auto-fit\2c minmax\(14rem\2c 1fr\)\)\]{
        grid-template-columns: repeat(auto-fit,minmax(14rem,1fr));
    }

    .sm\:\!flex-row{
        flex-direction: row !important;
    }

    .sm\:flex-row{
        flex-direction: row;
    }

    .sm\:flex-col{
        flex-direction: column;
    }

    .sm\:flex-nowrap{
        flex-wrap: nowrap;
    }

    .sm\:items-center{
        align-items: center;
    }

    .sm\:\!justify-start{
        justify-content: flex-start !important;
    }

    .sm\:justify-start{
        justify-content: flex-start;
    }

    .sm\:justify-end{
        justify-content: flex-end;
    }

    .sm\:justify-between{
        justify-content: space-between;
    }

    .sm\:\!gap-5{
        gap: 1.25rem !important;
    }

    .sm\:gap-10{
        gap: 2.5rem;
    }

    .sm\:gap-2{
        gap: 0.5rem;
    }

    .sm\:gap-6{
        gap: 1.5rem;
    }

    .sm\:gap-x-4{
        -moz-column-gap: 1rem;
             column-gap: 1rem;
    }

    .sm\:gap-x-8{
        -moz-column-gap: 2rem;
             column-gap: 2rem;
    }

    .sm\:gap-y-8{
        row-gap: 2rem;
    }

    .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * 0);
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * (1 - 0));
        margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
        margin-left: calc(0.5rem * calc(1 - 0));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * (1 - 0));
        margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
        margin-top: calc(0px * calc(1 - 0));
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * 0);
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .sm\:place-self-start{
        align-self: start;
        justify-self: start;
        place-self: start;
    }

    .sm\:place-self-end{
        align-self: end;
        justify-self: end;
        place-self: end;
    }

    .sm\:self-auto{
        align-self: auto;
    }

    .sm\:self-center{
        align-self: center;
    }

    .sm\:rounded-bl-none{
        border-bottom-left-radius: 0px;
    }

    .sm\:rounded-bl-xl{
        border-bottom-left-radius: 0.75rem;
    }

    .sm\:rounded-tr-none{
        border-top-right-radius: 0px;
    }

    .sm\:rounded-tr-xl{
        border-top-right-radius: 0.75rem;
    }

    .sm\:p-10{
        padding: 2.5rem;
    }

    .sm\:p-8{
        padding: 2rem;
    }

    .sm\:px-0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .sm\:px-16{
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .sm\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:px-7{
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }

    .sm\:px-8{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .sm\:py-4{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .sm\:pt-0{
        padding-top: 0px;
    }

    .sm\:pt-4{
        padding-top: 1rem;
    }

    .sm\:text-left{
        text-align: left;
    }

    .sm\:text-lg{
        font-size: 1.125rem;
        line-height: 1.75rem;
    }

    .sm\:text-xl{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .data-\[state\=open\]\:sm\:slide-in-from-bottom-full[data-state=open]{
        --tw-enter-translate-y: 100%;
    }
}
@media (min-width: 768px){

    .md\:absolute{
        position: absolute;
    }

    .md\:col-span-1{
        grid-column: span 1 / span 1;
    }

    .md\:col-span-2{
        grid-column: span 2 / span 2;
    }

    .md\:col-span-3{
        grid-column: span 3 / span 3;
    }

    .md\:col-span-full{
        grid-column: 1 / -1;
    }

    .md\:col-start-2{
        grid-column-start: 2;
    }

    .md\:row-start-1{
        grid-row-start: 1;
    }

    .md\:row-end-4{
        grid-row-end: 4;
    }

    .md\:mx-0{
        margin-left: 0px;
        margin-right: 0px;
    }

    .md\:mx-auto{
        margin-left: auto;
        margin-right: auto;
    }

    .md\:\!mt-0{
        margin-top: 0px !important;
    }

    .md\:-mb-3{
        margin-bottom: -0.75rem;
    }

    .md\:ml-auto{
        margin-left: auto;
    }

    .md\:mt-0{
        margin-top: 0px;
    }

    .md\:\!block{
        display: block !important;
    }

    .md\:\!inline{
        display: inline !important;
    }

    .md\:grid{
        display: grid;
    }

    .md\:h-3\/4{
        height: 75%;
    }

    .md\:h-auto{
        height: auto;
    }

    .md\:\!max-h-\[25vw\]{
        max-height: 25vw !important;
    }

    .md\:max-h-5{
        max-height: 1.25rem;
    }

    .md\:max-h-full{
        max-height: 100%;
    }

    .md\:\!w-3\/4{
        width: 75% !important;
    }

    .md\:w-1\/2{
        width: 50%;
    }

    .md\:w-1\/3{
        width: 33.333333%;
    }

    .md\:w-24{
        width: 6rem;
    }

    .md\:w-28{
        width: 7rem;
    }

    .md\:w-32{
        width: 8rem;
    }

    .md\:w-64{
        width: 16rem;
    }

    .md\:w-72{
        width: 18rem;
    }

    .md\:w-80{
        width: 20rem;
    }

    .md\:w-84{
        width: 21rem;
    }

    .md\:w-96{
        width: 24rem;
    }

    .md\:w-\[25vw\]{
        width: 25vw;
    }

    .md\:w-\[30rem\]{
        width: 30rem;
    }

    .md\:w-\[35rem\]{
        width: 35rem;
    }

    .md\:w-\[min\(calc\(50\%-0\.5rem\)\2c 8rem\)\]{
        width: min(calc(50% - 0.5rem),8rem);
    }

    .md\:w-\[var\(--radix-navigation-menu-viewport-width\)\]{
        width: var(--radix-navigation-menu-viewport-width);
    }

    .md\:w-auto{
        width: auto;
    }

    .md\:w-fit{
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
    }

    .md\:min-w-\[10rem\]{
        min-width: 10rem;
    }

    .md\:min-w-\[20rem\]{
        min-width: 20rem;
    }

    .md\:min-w-\[25rem\]{
        min-width: 25rem;
    }

    .md\:min-w-\[28rem\]{
        min-width: 28rem;
    }

    .md\:min-w-\[36rem\]{
        min-width: 36rem;
    }

    .md\:\!max-w-xl{
        max-width: 36rem !important;
    }

    .md\:max-w-\[20rem\]{
        max-width: 20rem;
    }

    .md\:max-w-\[420px\]{
        max-width: 420px;
    }

    .md\:max-w-\[8rem\]{
        max-width: 8rem;
    }

    .md\:flex-1{
        flex: 1 1 0%;
    }

    .md\:columns-2{
        -moz-columns: 2;
             columns: 2;
    }

    .md\:\!grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .md\:grid-cols-1{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .md\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .md\:grid-cols-\[1fr_auto\]{
        grid-template-columns: 1fr auto;
    }

    .md\:grid-cols-\[4rem_18rem_7rem\]{
        grid-template-columns: 4rem 18rem 7rem;
    }

    .md\:grid-cols-\[minmax\(24rem\2c 1fr\)_repeat\(auto-fill\2c minmax\(10rem\2c 1fr\)\)\]{
        grid-template-columns: minmax(24rem,1fr) repeat(auto - -webkit-fill-available,minmax(10rem,1fr));
        grid-template-columns: minmax(24rem,1fr) repeat(auto - fill,minmax(10rem,1fr));
    }

    .md\:grid-cols-\[repeat\(2\2c 18rem\)\]{
        grid-template-columns: repeat(2,18rem);
    }

    .md\:flex-row{
        flex-direction: row;
    }

    .md\:flex-row-reverse{
        flex-direction: row-reverse;
    }

    .md\:items-center{
        align-items: center;
    }

    .md\:justify-around{
        justify-content: space-around;
    }

    .md\:gap-1{
        gap: 0.25rem;
    }

    .md\:gap-x-2{
        -moz-column-gap: 0.5rem;
             column-gap: 0.5rem;
    }

    .md\:gap-y-0{
        row-gap: 0px;
    }

    .md\:place-self-auto{
        align-self: auto;
        justify-self: auto;
        place-self: auto;
    }

    .md\:place-self-center{
        align-self: center;
        justify-self: center;
        place-self: center;
    }

    .md\:justify-self-auto{
        justify-self: auto;
    }

    .md\:justify-self-end{
        justify-self: end;
    }

    .md\:truncate{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .md\:px-10{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .md\:px-16{
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .md\:px-3{
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .md\:px-4{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .md\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .md\:px-8{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .md\:py-0{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .md\:py-0\.5{
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
    }

    .md\:py-4{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .md\:pr-5{
        padding-right: 1.25rem;
    }

    .md\:text-2xl{
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
@media (min-width: 1024px){

    .lg\:\!col-span-4{
        grid-column: span 4 / span 4 !important;
    }

    .lg\:col-span-1{
        grid-column: span 1 / span 1;
    }

    .lg\:col-span-full{
        grid-column: 1 / -1;
    }

    .lg\:row-start-auto{
        grid-row-start: auto;
    }

    .lg\:\!block{
        display: block !important;
    }

    .lg\:block{
        display: block;
    }

    .lg\:\!hidden{
        display: none !important;
    }

    .lg\:w-1\/3{
        width: 33.333333%;
    }

    .lg\:w-2\/5{
        width: 40%;
    }

    .lg\:w-28{
        width: 7rem;
    }

    .lg\:w-96{
        width: 24rem;
    }

    .lg\:w-\[12ch\]{
        width: 12ch;
    }

    .lg\:w-\[37\.5rem\]{
        width: 37.5rem;
    }

    .lg\:max-w-3xl{
        max-width: 48rem;
    }

    .lg\:max-w-\[70ch\]{
        max-width: 70ch;
    }

    .lg\:max-w-md{
        max-width: 28rem;
    }

    .lg\:\!grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .lg\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lg\:grid-cols-5{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .lg\:grid-cols-\[1fr\2c auto\2c auto\2c auto\2c auto\]{
        grid-template-columns: 1fr auto auto auto auto;
    }

    .lg\:grid-cols-\[1fr\2c repeat\(3\2c 8rem\)\2c auto\]{
        grid-template-columns: 1fr repeat(3,8rem) auto;
    }

    .lg\:grid-cols-\[1fr_2fr\]{
        grid-template-columns: 1fr 2fr;
    }

    .lg\:flex-nowrap{
        flex-wrap: nowrap;
    }

    .lg\:overflow-auto{
        overflow: auto;
    }

    .lg\:truncate{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lg\:p-4{
        padding: 1rem;
    }

    .lg\:px-0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .lg\:px-14{
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    .lg\:text-3xl{
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .\*\:lg\:text-sm > *{
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
@media (min-width: 1280px){

    .xl\:inline-flex{
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .xl\:\!max-h-\[20vw\]{
        max-height: 20vw !important;
    }

    .xl\:w-2\/6{
        width: 33.333333%;
    }

    .xl\:max-w-\[40ch\]{
        max-width: 40ch;
    }

    .xl\:columns-3{
        -moz-columns: 3;
             columns: 3;
    }

    .xl\:\!grid-cols-4{
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }

    .xl\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .xl\:grid-cols-\[1fr_2fr\]{
        grid-template-columns: 1fr 2fr;
    }

    .xl\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .xl\:py-3{
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .xl\:py-4{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .\*\:xl\:text-base > *{
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
@media (min-width: 1536px){

    .\32xl\:max-w-sm{
        max-width: 24rem;
    }

    .\32xl\:p-6{
        padding: 1.5rem;
    }

    .\32xl\:px-9{
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }
}
.\[\&\+div\>input\]\:pl-11+div>input{
    padding-left: 2.75rem;
}
.\[\&\+div\>input\]\:pr-11+div>input{
    padding-right: 2.75rem;
}
.\[\&\+div\>label\]\:\!left-11+div>label{
    left: 2.75rem !important;
}
.\[\&\:first-child_\>_\*\:first-child\]\:rounded-tl:first-child > *:first-child{
    border-top-left-radius: 0.25rem;
}
.\[\&\:first-child_\>_\*\:last-child\]\:rounded-tr:first-child > *:last-child{
    border-top-right-radius: 0.25rem;
}
.\[\&\:last-child_\>_\*\:first-child\]\:rounded-bl:last-child > *:first-child{
    border-bottom-left-radius: 0.25rem;
}
.\[\&\:last-child_\>_\*\:last-child\]\:rounded-br:last-child > *:last-child{
    border-bottom-right-radius: 0.25rem;
}
.\[\&\:not\(\:last-child\)_\>_th\:empty\+th\:not\(\:empty\)\]\:border-l-2:not(:last-child) > th:empty+th:not(:empty){
    border-left-width: 2px;
}
.\[\&\:not\(\:last-child\)_\>_th\:not\(\:last-child\)\:not\(\:empty\)\]\:border-r-2:not(:last-child) > th:not(:last-child):not(:empty){
    border-right-width: 2px;
}
.\[\&\:not\(only\)\]\:hidden:not(only){
    display: none;
}
.\[\&\:nth-child\(even\)\>\*\]\:bg-bg:nth-child(even)>*{
    background-color: #F6F6F6;
}
.dark .\[\&\:nth-child\(even\)\>\*\]\:bg-bg:nth-child(even)>*{
    background-color: #1F1C20;
}
.\[\&\:nth-child\(odd\)\>\*\]\:bg-grp:nth-child(odd)>*{
    background-color: #FFFFFF;
}
.dark .\[\&\:nth-child\(odd\)\>\*\]\:bg-grp:nth-child(odd)>*{
    background-color: #373438;
}
.\[\&\>\*\:first-child\]\:ml-0>*:first-child{
    margin-left: 0px;
}
.\[\&\>\*\:last-child\]\:mr-0>*:last-child{
    margin-right: 0px;
}
.\[\&\>\*\:last-child\]\:text-gray-800>*:last-child{
    --tw-text-opacity: 1;
    color: rgba(31, 28, 32, 1);
    color: rgba(31, 28, 32, var(--tw-text-opacity));
}
.dark .\[\&\>\*\:last-child\]\:dark\:text-white>:last-child{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
@media (min-width: 640px){

    .sm\:\[\&\>\*\:last-child\]\:border-r>*:last-child{
        border-right-width: 1px;
    }

    .sm\:\[\&\>\*\:not\(\:last-child\)\]\:border-r>*:not(:last-child){
        border-right-width: 1px;
    }
}
.\[\&\>\*\:nth-child\(n\+3\)\]\:border-t>*:nth-child(n+3){
    border-top-width: 1px;
}
@media (min-width: 640px){

    .sm\:\[\&\>\*\:nth-child\(n\+3\)\]\:border-t-0>*:nth-child(n+3){
        border-top-width: 0;
    }
}
.\[\&\>\*\:nth-child\(odd\)\]\:border-r>*:nth-child(odd){
    border-right-width: 1px;
}
.\[\&\>\*\]\:sticky>*{
    position: sticky;
}
.\[\&\>\*\]\:top-0>*{
    top: 0px;
}
.\[\&\>\*\]\:mx-2>*{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.\[\&\>\*\]\:hidden>*{
    display: none;
}
.\[\&\>\*\]\:max-h-full>*{
    max-height: 100%;
}
.\[\&\>\*\]\:min-h-0>*{
    min-height: 0px;
}
.\[\&\>\*\]\:max-w-full>*{
    max-width: 100%;
}
.\[\&\>\*\]\:flex-\[1_1_calc\(50\%-0\.5rem\)\]>*{
    flex: 1 1 calc(50% - 0.5rem);
}
.\[\&\>\*\]\:border-gray-200>*{
    --tw-border-opacity: 1;
    border-color: rgba(207, 207, 207, 1);
    border-color: rgba(207, 207, 207, var(--tw-border-opacity));
}
.\[\&\>\*\]\:font-sans>*{
    font-family: var(--font-open-sans), Open Sans, sans-serif;
}
.\[\&\>\*\]\:text-gray-500>*{
    --tw-text-opacity: 1;
    color: rgba(104, 102, 104, 1);
    color: rgba(104, 102, 104, var(--tw-text-opacity));
}
.\[\&\>\*\]\:outline-2>*{
    outline-width: 2px;
}
.focus-within\:\[\&\>\*\]\:relative>*:focus-within{
    position: relative;
}
.focus-within\:\[\&\>\*\]\:z-10>*:focus-within{
    z-index: 10;
}
.focus-within\:\[\&\>\*\]\:outline>*:focus-within{
    outline-style: solid;
}
.focus-within\:\[\&\>\*\]\:outline-brand>*:focus-within{
    outline-color: rgb(253, 212, 2);
    outline-color: rgba(var(--brand, 253 212 2) / 1);
}
.\[\&\>\*\]\:hover\:text-gray-200:hover>*{
    --tw-text-opacity: 1;
    color: rgba(207, 207, 207, 1);
    color: rgba(207, 207, 207, var(--tw-text-opacity));
}
.dark .\[\&\>\*\]\:dark\:text-gray-100>*{
    --tw-text-opacity: 1;
    color: rgba(219, 219, 219, 1);
    color: rgba(219, 219, 219, var(--tw-text-opacity));
}
.group\/menu[data-menu=closed] .group-data-\[menu\=closed\]\/menu\:\[\&\>button\>span\]\:sr-only>button>span{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.\[\&\>div\]\:h-full>div{
    height: 100%;
}
.\[\&\>div\]\:w-full>div{
    width: 100%;
}
.\[\&\>input\]\:min-w-0>input{
    min-width: 0px;
}
.\[\&\>input\]\:pr-8>input{
    padding-right: 2rem;
}
.\[\&\>svg\]\:fill-gray-400>svg{
    fill: #B0AFB0;
}
.\[\&\>ul\]\:grid>ul{
    display: grid;
}
@media (min-width: 640px){

    .sm\:\[\&\>ul\]\:grid-cols-\[repeat\(auto-fill\2c minmax\(25rem\2c 1fr\)\)\]>ul{
        grid-template-columns: repeat(auto-fill,minmax(25rem,1fr));
    }
}
.\[\&_\#formImage\]\:\!opacity-100 #formImage{
    opacity: 1 !important;
}
.\[\&_\*\:\:-webkit-scrollbar-thumb\]\:border-none *::-webkit-scrollbar-thumb{
    border-style: none;
}
.\[\&_\*\:\:-webkit-scrollbar-thumb\]\:bg-transparent *::-webkit-scrollbar-thumb{
    background-color: transparent;
}
.\[\&_\*\:\:-webkit-scrollbar-track\]\:bg-transparent *::-webkit-scrollbar-track{
    background-color: transparent;
}
.\[\&_\*\:\:-webkit-scrollbar\]\:w-0 *::-webkit-scrollbar{
    width: 0px;
}
.\[\&_\*\]\:box-border *{
    box-sizing: border-box;
}
.\[\&_\*\]\:h-10 *{
    height: 2.5rem;
}
@keyframes pulse{

    50%{
        opacity: .5;
    }
}
.\[\&_\*\]\:animate-pulse *{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.\[\&_\*\]\:rounded *{
    border-radius: 0.25rem;
}
.\[\&_\*\]\:bg-gray-100 *{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 219, 219, 1);
    background-color: rgba(219, 219, 219, var(--tw-bg-opacity));
}
.\[\&_\*\]\:\[scrollbar-color\:transparent_transparent\] *{
    scrollbar-color: transparent transparent;
}
.\[\&_\*\]\:\[scrollbar-width\:0\] *{
    scrollbar-width: 0;
}
.dark .\[\&_\*\]\:dark\:bg-gray-500 *{
    --tw-bg-opacity: 1;
    background-color: rgba(104, 102, 104, 1);
    background-color: rgba(104, 102, 104, var(--tw-bg-opacity));
}
.\[\&_\.comment\]\:w-full .comment{
    width: 100%;
}
.dark .dark\:\[\&_\.dark-shadow_svg\]\:drop-shadow-dc .dark-shadow svg{
    --tw-drop-shadow: drop-shadow(0 0 2px rgba(255,255,255,0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) drop-shadow(0 0 2px rgba(255,255,255,0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\[\&_\.first\]\:w-full .first{
    width: 100%;
}
.\[\&_\.rs\\\\_\\\\_control\]\:h-full .rs\_\_control{
    height: 100%;
}
.\[\&_\>_button\]\:box-border > button{
    box-sizing: border-box;
}
.\[\&_\>_button\]\:h-10 > button{
    height: 2.5rem;
}
.\[\&_\>_svg\]\:fill-gray-400 > svg{
    fill: #B0AFB0;
}
.\[\&_\>_svg\]\:fill-gray-800 > svg{
    fill: #1F1C20;
}
.dark .\[\&_\>_svg\]\:dark\:fill-gray-400 > svg{
    fill: #B0AFB0;
}
.\[\&_a\]\:text-blue-400 a{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, 1);
    color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.\[\&_button\]\:text-red-400 button{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, 1);
    color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.dark .\[\&_button\]\:dark\:bg-red-700 button{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, 1);
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.\[\&_circle\]\:opacity-50 circle{
    opacity: 0.5;
}
.\[\&_input\]\:mt-0 input{
    margin-top: 0px;
}
.\[\&_input\]\:rounded-r-none input{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.\[\&_label\]\:self-center label{
    align-self: center;
}
.\[\&_li\]\:inline-flex li{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.\[\&_li\]\:justify-stretch li{
    justify-content: stretch;
}
@media not all and (min-width: 768px){

    .\[\&_li\]\:max-md\:grow li{
        flex-grow: 1;
    }
}
.\[\&_ol\]\:list-decimal ol{
    list-style-type: decimal;
}
.\[\&_p\:nth-child\(2\)\]\:capitalize p:nth-child(2){
    text-transform: capitalize;
}
.\[\&_table\]\:mx-2 table{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.\[\&_ul\]\:list-disc ul{
    list-style-type: disc;
}
.\[\&\~\*\]\:mb-2~*{
    margin-bottom: 0.5rem;
}
@media not all and (min-width: 768px){

    .cs .\[\.cs_\&\]\:max-md\:sr-only{
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
}
.leaflet-control-locate.active .\[\.leaflet-control-locate\.active_\&\]\:bg-\[url\(\'\/locate_s\.svg\'\)\]{
    background-image: url('/locate_s.svg');
}
tbody>tr:first-of-type .\[tbody\>tr\:first-of-type_\&\]\:hidden{
    display: none;
}
tbody>tr:last-of-type .\[tbody\>tr\:last-of-type_\&\]\:hidden{
    display: none;
}

@font-face {
font-family: '__openSans_420557';
src: url(/_next/static/media/ec2563875dcf6d6c-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: '__openSans_420557';
src: url(/_next/static/media/b8eef19e7ff543fb-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__openSans_Fallback_420557';src: local("Arial");ascent-override: 101.58%;descent-override: 27.84%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_420557 {font-family: '__openSans_420557', '__openSans_Fallback_420557'
}.__variable_420557 {--font-open-sans: '__openSans_420557', '__openSans_Fallback_420557'
}

